import { stringify } from 'query-string';
import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck'

// ================ Action types ================ //

export const GET_MODEL_RESULTS_REQUEST = 'app/ProfileSettingsPage/GET_MODEL_RESULTS_REQUEST';
export const GET_MODEL_RESULTS_SUCCESS = 'app/ProfileSettingsPage/GET_MODEL_RESULTS_SUCCESS';
export const GET_MODEL_RESULTS_ERROR = 'app/ProfileSettingsPage/GET_MODEL_RESULTS_ERROR';

// ================ Reducer ================ //

const initialState = {
  getModelResultsError: null,
  getModelResultsInProgress: false,
  modelResults: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
      case GET_MODEL_RESULTS_REQUEST: {
        // payload.params: { id: 'tempId', file }
        return {
          ...state,
          modelResults: null,
          getModelResultsInProgress: true,
          getModelResultsError: null,
        };
      }
      case GET_MODEL_RESULTS_SUCCESS: {
        // payload: { json }
        return { 
          ...state, 
          modelResults: payload, 
          getModelResultsInProgress: false 
        };
      }
      case GET_MODEL_RESULTS_ERROR: {
        // eslint-disable-next-line no-console
        return { 
          ...state, 
          modelResults: null, 
          getModelResultsInProgress: false, 
          getModelResultsError: payload.error 
        };
      }
      default:
        return state;
  }
}

// ================ Action creators ================ //

export const getModelResultsRequest = params => ({ type: GET_MODEL_RESULTS_REQUEST, payload: { params } });
export const getModelResultsSuccess = result => ({ type: GET_MODEL_RESULTS_SUCCESS, payload: result });
export const getModelResultsError = error => ({ type: GET_MODEL_RESULTS_ERROR, payload: error, error: true });

// ================ Thunk ================ //

export const getModelResults = actionPayload => {
  return (dispatch, getState, sdk) => {
    dispatch(getModelResultsRequest(actionPayload));
    dispatch(fetchCurrentUser())
      .then(() => {
        const currentUser = getState().user.currentUser
        const privateData = currentUser?.attributes.profile.privateData;
        const uscf_id = privateData?.questions.find(e => e.questionText === "What is your USCF ID?").answer
        const fide_id = privateData?.questions.find(e => e.questionText === "What is your FIDE ID?").answer;
        const query = {
          first_name: currentUser?.attributes.profile.firstName.toLowerCase(),
          last_name: currentUser?.attributes.profile.lastName.toLowerCase(),
          chesscom_username: privateData?.questions.find(e => e.questionText === "What is your chess.com username?").answer.toLowerCase(),
          lichess_username: privateData?.questions.find(e => e.questionText === "What is your lichess username?").answer.toLowerCase(),
          uscf_id: uscf_id ? uscf_id : 0,
          fide_id: fide_id ? fide_id : 0,
        }
        fetch(
          `https://greendinosaur.pythonanywhere.com/chessalyzer_result/${query.first_name}/${query.last_name}/${query.lichess_username}/${query.chesscom_username}/${query.uscf_id}/${query.fide_id}`,
          { 
            method: 'GET', 
            headers: {
              "Accept": "application/json",
            },
          }
        )
        .then(
          res => {
            return res.json();
          }
        )
        .then(
          json => {
            dispatch(getModelResultsSuccess(json));
          }
        )
        .catch(
          e => {
            dispatch(getModelResultsError(storableError(e)))
          }
        )
      })
  }
}

export const loadData = (params, search, config) => {
  return (dispatch, setState, sdk) => {
    return Promise.all([dispatch(getModelResults())]);
  }
}