import { types as sdkTypes } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { fetchCurrentUser } from '../../ducks/user.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/SavedCoachesPage/SET_INITIAL_STATE';

export const FETCH_USERS_REQUEST = 'app/SavedCoachesPage/FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'app/SavedCoachesPage/FETCH_USERS_SUCCESS';
export const FETCH_USERS_ERROR = 'app/SavedCoachesPage/FETCH_USERS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchSavedCoachesProgress: false,
  fetchSavedCoachesError: null,
  savedCoaches: [],
};

const savedCoachesPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        fetchSavedCoachesProgress: true,
        fetchSavedCoachesError: null,
        savedCoaches: [],
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        savedCoaches: payload.data,
        fetchSavedCoachesProgress: false,
      };
    case FETCH_USERS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        fetchSavedCoachesProgress: false,
        fetchSavedCoachesError: payload
      };

    default:
      return state;
  }
};

export default savedCoachesPageReducer;

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const fetchSavedCoachesRequest = () => ({
  type: FETCH_USERS_REQUEST,
});

export const fetchSavedCoachesSuccess = response => ({
  type: FETCH_USERS_SUCCESS,
  payload: { data: response },
});

export const fetchSavedCoachesError = e => ({
  type: FETCH_USERS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

// Throwing error for new (loadData may need that info)
export const querySavedCoaches = currentUser => (dispatch, getState, sdk) => {
  dispatch(fetchSavedCoachesRequest());
  const { saved_coaches } = currentUser?.attributes.profile.privateData || {};
  if (!saved_coaches) {
    return dispatch(setInitialState());
  }
  const saved_coaches_uuids = saved_coaches.map((id) => new UUID(id))
  dispatch(showUser(saved_coaches_uuids));

  const allPromises = saved_coaches_uuids.map(userId => {
    return dispatch(showUser(userId)); // Returns a promise
  });

  Promise.all(allPromises).then((response) => {
    dispatch(fetchSavedCoachesSuccess(response));
    return response;
  });
};

export const showUser = userId => (dispatch, getState, sdk) => {
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      // had to return first element because it denormalise response and return data in array.
      return denormalisedResponseEntities(response)[0];
    })
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([dispatch(fetchCurrentUser())])
    .then(response => {
      const currentUser = getState().user.currentUser;
      return dispatch(querySavedCoaches(currentUser));
    })
    .catch(e => {
      throw e;
    });
};
