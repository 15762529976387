/**
 * Transaction process graph for product orders:
 *   - reviewer-profile
 */

// As this process should never be actually used, there are only dummy responses here

export const transitions = {};

export const states = {
  INITIAL: 'initial',
};

export const graph = {
  id: 'reviewer-profile/release-1',

  initial: states.INITIAL,

  states: {
    [states.INITIAL]: {
      on: {},
    },
  }
};
export const isCustomerReview = transition => {
  return false;
};

export const isProviderReview = transition => {
  return false;
};

export const isPrivileged = transition => {
  return false;
};

export const isCompleted = transition => {
  return false;
};

export const isRefunded = transition => {
  return false;
};

export const isPaypalRefunded = transition => {
  return false;
}

export const isDisburse = transition => {
  return false;
};

export const statesNeedingProviderAttention = [];
