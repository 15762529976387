import { React, Component } from 'react'

import {
  H4,
  Button,
  ExternalLink,
  Modal,
  Heading,
} from '../../components'

import { FormattedMessage, injectIntl } from '../../util/reactIntl';

import css from './PaypalPayout.module.css';
import { parse } from '../../util/urlHelpers';

const errorToMessage = error => {
  return <FormattedMessage id="PaypalPayout.error" values={{ error: `${error.name}: ${error.message}` }}/>
}

class PaypalPayoutComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      removeModalOpen: false
    };

    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  handleOpenModal() {
    this.setState({ removeModalOpen: true, });
  }

  handleCloseModal() {
    this.setState({ removeModalOpen: false, });
  }

  render() {
    const {
      currentUser,
      onPaypalCreatePartnerReferral,
      updatePaypalAfterOnboardingInProgress,
      updatePaypalAfterOnboardingError,
      removePaypalAccountInProgress,
      removePaypalAccountError,
      onRemovePaypalAccount,
      createPartnerReferralInProgress,
      createPartnerReferralError,
      createPartnerReferralLink,
      paypalAccount,
      fetchPaypalAccountStatusInProgress,
      fetchPaypalAccountStatusError,
      paypalAccountStatus,
      returnUrl,
      redirect,
      onManageDisableScrolling,
      intl,
    } = this.props

    const paypalConnected =
        currentUser && 
        currentUser.attributes.profile.privateData.paypalAccount && 
        !!currentUser.attributes.profile.publicData.paypalAccountId;

    const handleCreatePaypalAccount = () => {
      onPaypalCreatePartnerReferral({
        partner_config_override: {
          return_url: returnUrl,
          return_url_description: 'the url to return to after onboarding',
          show_add_credit_card: true,
          partner_logo_url: 'https://chessvia.com/static/media/whyUse1.2ba87dd56515af089e46.png'
        }
      });
    }

    const paymentsReceivable = paypalAccountStatus && paypalAccountStatus.payments_receivable;
    const emailConfirmed = paypalAccountStatus && paypalAccountStatus.primary_email_confirmed;
    const oauthValid = paypalAccountStatus && paypalAccountStatus.oauth_integrations.find(
        entry => entry.oauth_third_party.find(
          party => party.partner_client_id === process.env.REACT_APP_PAYPAL_CLIENT_ID
        )
      )
    const vettingStatusSubscribed = paypalAccountStatus && paypalAccountStatus.products.filter(
        entry => (entry.name === "PPCP_CUSTOM" || entry.name === "PPCP_STANDARD")
      ).every(
        entry => entry.vetting_status === "SUBSCRIBED"
      )
    
    const requirementsMissing = 
      paypalAccount && paypalAccountStatus &&
      (
        !paymentsReceivable ||
        !emailConfirmed ||
        !oauthValid ||
        !vettingStatusSubscribed
      )

    const requirementsMissingMessage = paypalConnected && paypalAccountStatus ? (
        !paymentsReceivable ? <FormattedMessage id="PaypalPayout.paymentsReceivableMissing" /> :
        !emailConfirmed ? <FormattedMessage id="PaypalPayout.emailConfirmedMissing" /> :
        !oauthValid ? <FormattedMessage id="PaypalPayout.oauthValidMissing" /> :
        !vettingStatusSubscribed ? <FormattedMessage id="PaypalPayout.vettingStatusIncorrect" /> :
        <FormattedMessage id="PaypalPayout.paypalConnected" />
      ) : null

    const referralLinkButtons = (
      createPartnerReferralLink ? (
        <ExternalLink className={css.button} key="linkToPaypal" href={createPartnerReferralLink} title={"linkToPaypal"}>
          <FormattedMessage id="PaypalPayout.gotoLink" />
        </ExternalLink>
      ) : (
        !paypalConnected || requirementsMissing ? (
          <Button 
            className={css.button}
            inProgress={createPartnerReferralInProgress}
            onClick={handleCreatePaypalAccount}
          >
            <FormattedMessage id="PaypalPayout.getLink" />
          </Button>
        ) : null
      )
    )

    const currentStatusMessage = 
      fetchPaypalAccountStatusInProgress ? <FormattedMessage id="PaypalPayout.fetchStatusInProgress" /> :
      updatePaypalAfterOnboardingInProgress ? <FormattedMessage id="PaypalPayout.updateInProgress" /> :
      createPartnerReferralInProgress ? <FormattedMessage id="PaypalPayout.createLinkInProgress" /> :
      removePaypalAccountInProgress ? <FormattedMessage id="PaypalPayout.removeAccountInProgress" /> :
      createPartnerReferralError ? errorToMessage(createPartnerReferralError) :
      fetchPaypalAccountStatusError ? errorToMessage(fetchPaypalAccountStatusError) :
      updatePaypalAfterOnboardingError ? errorToMessage(updatePaypalAfterOnboardingError) :
      removePaypalAccountError ? errorToMessage(removePaypalAccountError) :
      null

    const removeAccountButton = paypalConnected ? (
      <Button 
        className={css.redButton}
        inProgress={removePaypalAccountInProgress}
        onClick={this.handleOpenModal}
      >
        <FormattedMessage id="PaypalPayout.removeAccount" />
      </Button>
    ) : null;

    const removeAccountConfirmModal = (
      <Modal
        id="PaypalPayout.removeModal"
        isOpen={this.state.removeModalOpen}
        onClose={this.handleCloseModal}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <div className={css.modal}>
          <Heading as="h2" rootClassName={css.modalTitle}>
            <FormattedMessage id="PaypalPayout.removeAccountTitle" />
          </Heading>
            <p className={css.modalMessage}>
              {intl.formatMessage({id: "PaypalPayout.removeAccountMessage"})}
            </p>
          <Button 
            className={css.redButton}
            inProgress={removePaypalAccountInProgress}
            onClick={() => {
              this.handleCloseModal();
              onRemovePaypalAccount();
            }}
          >
            <FormattedMessage id="PaypalPayout.removeAccount" />
          </Button>
        </div>
      </Modal>
    )

    const redirectMaybe = paypalConnected && redirect ? redirect : null;

    return (
      <div className={css.root}>
        <div className={css.content}>
          <H4 as="h1" className={css.heading}>
            <FormattedMessage id="PaypalPayout.heading" />
          </H4>
          {removeAccountConfirmModal}
          {redirectMaybe}
          {requirementsMissingMessage}
          {referralLinkButtons}
          {currentStatusMessage}
          {removeAccountButton}
        </div>
      </div>
    )
  }
}

const PaypalPayout = props => {
  return (
    <PaypalPayoutComponent
      {...props}
    />
  );
}

export default injectIntl(PaypalPayout);