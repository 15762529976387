/**
 * Transaction process graph for product orders:
 *   - review-request
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  // Transaction begins with an offer made by the provider
  PROVIDER_OFFER: 'transition/provider-offer',

  // Customer can accept
  CUSTOMER_AGREEMENT: 'transition/customer-agreement',

  // Or cancel
  CUSTOMER_OFFER_CANCEL: 'transition/customer-offer-cancel',

  // Provider can cancel too
  PROVIDER_OFFER_CANCEL: 'transition/provider-offer-cancel',

  // Provider can change the price
  CHANGE_PROVIDER_OFFER: 'transition/change-provider-offer',
};

export const states = {
  INITIAL: 'initial',
  WAITING_FOR_CUSTOMER: 'waiting-for-customer',
  ACCEPTED: 'accepted',
  CANCELED_NEGOTIATION: 'canceled-negotiation',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
  // id is defined only to support Xstates format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'review-request/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // Statess
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.PROVIDER_OFFER]: states.WAITING_FOR_CUSTOMER,
      },
    },
    [states.WAITING_FOR_CUSTOMER]: {
      on: {
        [transitions.CHANGE_PROVIDER_OFFER]: states.WAITING_FOR_CUSTOMER,
        [transitions.CUSTOMER_AGREEMENT]: states.ACCEPTED,
        [transitions.CUSTOMER_OFFER_CANCEL]: states.CANCELED_NEGOTIATION,
        [transitions.PROVIDER_OFFER_CANCEL]: states.CANCELED_NEGOTIATION,
      },
    },
  },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    transitions.CHANGE_PROVIDER_OFFER,
    transitions.CUSTOMER_AGREEMENT,
  ].includes(transition);
};
export const isCustomerReview = transition => {
  return false;
};

export const isProviderReview = transition => {
  return false;
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return false;
};

// Check when transaction is completed (item is received and review notifications sent)
export const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.ACCEPTED,
  ];
  return txCompletedTransitions.includes(transition);
};

// Check when transaction is refunded (order did not happen)
// In these transitions action/stripe-refund-payment is called
export const isRefunded = transition => {
  return false;
};

export const isPaypalRefunded = transition => {
  return false;
}

export const isDisburse = transition => {
  return false;
};

export const statesNeedingProviderAttention = [];
