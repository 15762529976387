import { parse } from "../../util/urlHelpers"

import { fetchCurrentUser } from "../../ducks/user.duck"
import {
  updatePaypalAfterOnboarding,
  fetchPaypalAccountStatus
} from "../../ducks/paypalAccount.duck"

export const loadData = (params, search, config) => (dispatch, getState, sdk) => { 
  const { returnURLType } = params;
  const paypalUpdate = returnURLType === 'paypal' ? [dispatch(updatePaypalAfterOnboarding(parse(search)))] : []
  
  return Promise.all(paypalUpdate)
    .then(res => 
      dispatch(fetchCurrentUser())
    )
    .then(res => {
      const currentUser = getState().user.currentUser;

      const getPaypalData = currentUser && currentUser.attributes.profile.privateData.paypalAccount ? 
        [dispatch(fetchPaypalAccountStatus(currentUser.id.uuid))] : []
      
      return Promise.all([
        ...getPaypalData
      ])
      
    })
}