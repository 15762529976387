import { types as sdkTypes } from '../../util/sdkLoader';
const { UUID } = sdkTypes;

// Action types

import appSettings from "../../config/settings";
import { addMarketplaceEntities, getMarketplaceEntities } from "../../ducks/marketplaceData.duck";
import { storableError } from "../../util/errors";

export const GET_LISTING_REQUEST = 'app/DirectServicePage/GET_LISTING_REQUEST';
export const GET_LISTING_SUCCESS = 'app/DirectServicePage/GET_LISTING_SUCCESS';
export const GET_LISTING_ERROR = 'app/DirectServicePage/GET_LISTING_ERROR';

// Reducer

const initialState = {
  listingRef: [],
  getListingInProgress: false,
  getListingError: null,
}

const directServicePageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GET_LISTING_REQUEST:
      return { ...state, getListingInProgress: true };
    case GET_LISTING_SUCCESS:
      return { ...state, getListingInProgress: false, listingRef: payload.data.data };
    case GET_LISTING_ERROR:
      return { ...state, getListingInProgress: false, getListingError: payload };
    default:
      return state;
  }
};

export default directServicePageReducer;

// Action creators

export const getListingRequest = () => ({ type: GET_LISTING_REQUEST });
export const getListingSuccess = payload => ({ type: GET_LISTING_SUCCESS, payload });
export const getListingError = payload => ({ type: GET_LISTING_ERROR, error: true, payload });

// Thunks

export const getListing = (listingId, config) => (dispatch, getState, sdk) => {
  dispatch(getListingRequest());
  const params = {
    id: new UUID(listingId),
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
  }

  return sdk.listings.show(params)
    .then(res => {
      dispatch(addMarketplaceEntities(res));
      dispatch(getListingSuccess(res))
      return res;
    })
    .catch(e => {
      dispatch(getListingError(storableError(e)));
      throw e;
    })
}

export const loadData = (params, search, config) => dispatch => {
  const listingId = appSettings.directServiceId;

  return Promise.all([
    dispatch(getListing(listingId, config))
  ])
}