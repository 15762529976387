import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import pick from 'lodash/pick';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

const { UUID, Money } = sdkTypes;

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/OfferPage/SET_INITIAL_VALUES';

export const FETCH_LISTING_REQUEST = 'app/OfferPage/FETCH_LISTING_REQUEST';
export const FETCH_LISTING_SUCCESS = 'app/OfferPage/FETCH_LISTING_SUCCESS';
export const FETCH_LISTING_ERROR = 'app/OfferPage/FETCH_LISTING_ERROR';

export const FETCH_OFFERS_REQUEST = 'app/OfferPage/FETCH_OFFERS_REQUEST';
export const FETCH_OFFERS_LISTING_SUCCESS = 'app/OfferPage/FETCH_OFFERS_LISTING_SUCCESS';
export const FETCH_OFFERS_TRANSACTION_SUCCESS = 'app/OfferPage/FETCH_OFFERS_TRANSACTION_SUCCESS';
export const FETCH_OFFERS_ERROR = 'app/OfferPage/FETCH_OFFERS_ERROR';

export const TRANSITION_REQUEST = 'app/OfferPage/TRANSITION_REQUEST';
export const TRANSITION_SUCCESS = 'app/OfferPage/TRANSITION_SUCCESS';
export const TRANSITION_ERROR = 'app/OfferPage/TRANSITION_ERROR';

export const CREATE_OFFER_REQUEST = 'app/OfferPage/CREATE_OFFER_REQUEST';
export const CREATE_OFFER_LISTING_SUCCESS = 'app/OfferPage/CREATE_OFFER_LISTING_SUCCESS';
export const CREATE_OFFER_TRANSACTION_SUCCESS = 'app/OfferPage/CREATE_OFFER_TRANSACTION_SUCCESS';
export const CREATE_OFFER_ERROR = 'app/OfferPage/CREATE_OFFER_ERROR';

export const CANCEL_OFFER_REQUEST = 'app/OfferPage/CANCEL_OFFER_REQUEST';
export const CANCEL_OFFER_SUCCESS = 'app/OfferPage/CANCEL_OFFER_SUCCESS';
export const CANCEL_OFFER_ERROR = 'app/OfferPage/CANCEL_OFFER_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchListingInProgress: false,
  fetchListingError: null,
  listing: null,

  fetchOffersInProgress: false,
  fetchOffersError: null,
  offerListingRefs: [],
  offerTransactionRefs: [],

  transitionInProgress: false,
  transitionError: null,

  createOfferInProgress: false,
  createOfferError: null,
  createOfferTransactionRef: null,
  createOfferListingRef: null,

  cancelOfferInProgress: false,
  cancelOfferError: null,
}

export default function transactionPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch(type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_LISTING_REQUEST:
      return { ...state, fetchListingInProgress: true, fetchListingError: null };
    case FETCH_LISTING_SUCCESS:
      return { ...state, fetchListingInProgress: false, listing: payload.data.data };
    case FETCH_LISTING_ERROR:
      return { ...state, fetchListingInProgress: false, fetchListingError: payload };

    case FETCH_OFFERS_REQUEST:
      return { ...state, fetchOffersInProgress: true, fetchOffersError: null };
    case FETCH_OFFERS_LISTING_SUCCESS: {
      return { ...state, fetchOffersInProgress: false, offerListingRefs: payload.data.data };
    }
    case FETCH_OFFERS_TRANSACTION_SUCCESS: {
      const offers = sortedTransactions(payload.data.data);
      return { ...state, fetchOffersInProgress: false, offerTransactionRefs: offers };
    }
    case FETCH_OFFERS_ERROR:
      return { ...state, fetchOffersInProgress: false, fetchOffersError: payload };
    
    case TRANSITION_REQUEST:
      return { ...state, transitionInProgress: true, transitionError: null };
    case TRANSITION_SUCCESS:
      return { ...state, transitionInProgress: false };
    case TRANSITION_ERROR:
      return { ...state, transitionInProgress: false, transitionError: payload };

    case CREATE_OFFER_REQUEST:
      return { ...state, createOfferInProgress: true, createOfferError: null };
    case CREATE_OFFER_LISTING_SUCCESS:
        return { 
          ...state, 
          createOfferListingRef: payload, 
        };
    case CREATE_OFFER_TRANSACTION_SUCCESS:
      return { 
        ...state, 
        createOfferInProgress: false, 
        createOfferTransactionRef: payload 
      };
    case CREATE_OFFER_ERROR:
      return { ...state, createOfferInProgress: false, createOfferError: payload };
    case CANCEL_OFFER_REQUEST:
      return { ...state, cancelOfferInProgress: true, cancelOfferError: null };
    case CANCEL_OFFER_SUCCESS:
      return { ...state, cancelOfferInProgress: false };
    case CANCEL_OFFER_ERROR:
      return { ...state, cancelOfferInProgress: false, cancelOfferError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const fetchListingRequest = () => ({ type: FETCH_LISTING_REQUEST });
export const fetchListingSuccess = response => ({
  type: FETCH_LISTING_SUCCESS,
  payload: response,
})
export const fetchListingError = e => ({ type: FETCH_LISTING_ERROR, error: true, payload: e });

export const fetchOffersRequest = () => ({ type: FETCH_OFFERS_REQUEST });
export const fetchOffersListingSuccess = response => ({
  type: FETCH_OFFERS_LISTING_SUCCESS,
  payload: response,
})
export const fetchOffersTransactionSuccess = response => ({
  type: FETCH_OFFERS_TRANSACTION_SUCCESS,
  payload: response,
})
export const fetchOffersError = e => ({ type: FETCH_OFFERS_ERROR, error: true, payload: e });

export const transitionRequest = () => ({ type: TRANSITION_REQUEST });
export const transitionSuccess = response => ({ type: TRANSITION_SUCCESS })
export const transitionError = e => ({ type: TRANSITION_ERROR, error: true, payload: e });

export const createOfferRequest = () => ({ type: CREATE_OFFER_REQUEST });
export const createOfferListingSuccess = response => ({
  type: CREATE_OFFER_LISTING_SUCCESS,
  payload: response,
})
export const createOfferTransactionSuccess = response => ({
  type: CREATE_OFFER_TRANSACTION_SUCCESS,
  payload: response,
})
export const createOfferError = e => ({ type: CREATE_OFFER_ERROR, error: true, payload: e });

export const cancelOfferRequest = () => ({ type: CANCEL_OFFER_REQUEST });
export const cancelOfferSuccess = response => ({ type: CANCEL_OFFER_SUCCESS })
export const cancelOfferError = e => ({ type: CANCEL_OFFER_ERROR, error: true, payload: e });

// ================ Thunks ================ //

// Gets current listing
const fetchListing = (id, config) => (dispatch, getState, sdk) => {
  dispatch(fetchListingRequest());
  sdk.listings.show({ 
    id,
    include: [
      'author',
    ] 
  })
  .then(res => {
    dispatch(fetchListingSuccess(res));
    return res;
  })
  .catch(e => {
    dispatch(fetchListingError(storableError(e)));
    throw e;
  });
}

// Gets offers with this listing id
const fetchOffers = (id) => (dispatch, getState, sdk) => {
  dispatch(fetchOffersRequest());
  sdk.transactions.query({
    listingId: id,
    lastTransitions: ['transition/provider-offer'],
    include: [
      'customer',
      'customer.profileImage'
    ]
  })
  .then(res => {
    dispatch(addMarketplaceEntities(res));
    dispatch(fetchOffersTransactionSuccess(res));

    sdk.listings.query({
      ids: res.data.data.map(transaction => transaction.attributes.protectedData.listingId),
      include: [ 'author' ]
    })
    .then(res => {
      dispatch(addMarketplaceEntities(res))
      dispatch(fetchOffersListingSuccess(res))
    })
  })
  .catch(e => {
    dispatch(fetchOffersError(storableError(e)));
    throw e;
  })
}

export const createOffer = (id, processAlias, transition, params) => (dispatch, getState, sdk) => {
  dispatch(createOfferRequest());

  const queryParams = {
    include: ['currentStock'],
    expand: true,
  };

  return sdk.ownListings.create(
    {
      title: "Offer for " + id,
      price: {
        amount: params.protectedData.offerValue,
        currency: params.protectedData.offerValueCurrency
      },
      publicData: { 
        listingId: id,
        hidden: true,
        transactionProcessAlias: "review-service-async/release-1", // TODO: Make this not hardcoded
        unitType: "item",
        ...params.protectedData,
      },
    }, 
    queryParams
  )
  .then(res => {
    dispatch(addMarketplaceEntities(res))
    dispatch(createOfferListingSuccess(res))
    
    const newParams = {
      protectedData: {
        listingId: res.data.data.id.uuid,
        ...params.protectedData
      }
    }
    const queryParams = {
      include: ['listing.currentStock'],
      expand: true,
    };

    sdk.stock.compareAndSet({
      listingId: res.data.data.id.uuid,
      oldTotal: null,
      newTotal: 1,
    })

    return sdk.transactions.initiate(
      {
        processAlias,
        transition,
        params: { 
          listingId: id,
          ...newParams,
        },
      }, 
      queryParams,
    )
  })
  .then(res => {
    dispatch(addMarketplaceEntities(res))
    dispatch(createOfferTransactionSuccess(res))
    dispatch(fetchOffers(id))
  })
  .catch(e => {
    dispatch(createOfferError(storableError(e)));
    throw e;
  })
}

export const cancelOffer = (id) => (dispatch, getState, sdk) => {
  dispatch(cancelOfferRequest());

  sdk.transactions.transition({
    id,
    transition: "transition/provider-offer-cancel",
    params: {},
  }, { expand: true })
  .then(res => {
      return sdk.ownListings.close({
        id: res.data.data.attributes.protectedData.listingId,
      })
    }
  )
  .then(res => {
    dispatch(cancelOfferSuccess());
    dispatch(fetchOffers(id));
    return res;
  })
  .catch(e => {
    dispatch(cancelOfferError(storableError(e)));
    throw e;
  })
}

export const loadData = (params, search, config) => (dispatch, getState) => {
  const listingId = new UUID(params.id);

  return Promise.all([
    dispatch(fetchOffers(listingId)),
    dispatch(fetchListing(listingId, config))
  ])
};