/**
  We can just add/remove/update the links of header menu from here.
  Changes will be reflected on dekstop and mobile both.
**/

export const aiAnalysisMenuLinks = {
  name: "aiAnalysisMenu",
  links: [
    { key: "ChessalyzerResultsPage", props: { name: "ChessalyzerResultsPage" } },
    { key: "OpeningCreatorPage",     props: { name: "OpeningCreatorPage" } },
    { key: "TrainingPlansPage",      props: { name: "TrainingPlansPage" } },
    { key: "ChessalyzerChatbotPage", props: { name: "ChessalyzerChatbotPage" } },
  ]
}

export const moreMenuLinks = {
  name: "moreMenu",
  links: [
    { key: "NewListingPageReviewer", props: { name: "NewListingPageReviewer" } },
    { key: "SavedCoachesPage",       props: { name: "SavedCoachesPage" } },
    { key: "FAQPage",                props: { name: "CMSPage", params: { pageId: 'faq' } } },
    { key: "AboutPage",              props: { name: "AboutPage", params: { pageId: 'about' } } },
    { key: "ReferralPage",           props: { name: "ReferralPage" } },
    { key: "SupportPage",            props: { name: "SupportPage", params: { pageId: 'support' } } },
  ]
}

export const browseMenuLinks = {
  name: "browseMenu",
  links: [
    { key: "SearchPageCoaches", props: { name: "SearchPage", to: { search: "?pub_listingType=reviewer-profile" } } },
    { key: "SearchPageSync",    props: { name: "SearchPage", to: { search: "?pub_listingType=review-service" } } },
    { key: "SearchPageAsync",   props: { name: "SearchPage", to: { search: "?pub_listingType=review-service-async" } } },
  ]
}

export const browseMenuCoachLinks = {
  name: "browseMenu",
  links: [
    { key: "SearchPageCoaches", props: { name: "SearchPage", to: { search: "?pub_listingType=reviewer-profile" } } },
    { key: "SearchPageSync",    props: { name: "SearchPage", to: { search: "?pub_listingType=review-service" } } },
    { key: "SearchPageAsync",   props: { name: "SearchPage", to: { search: "?pub_listingType=review-service-async" } } },
    { key: "SearchPageRequests",   props: { name: "SearchPage", to: { search: "?pub_listingType=review-request" } } }
  ]
}

export  const createRequestMenuLinks = {
  name: "createRequestMenu",
  links: [
    { key: "NewListingPageRequestGameReview",            props: { name: "NewListingPageRequest" } },
    { key: "NewListingPageRequestAccountReview",         props: { name: "NewListingPageRequest" } },
    { key: "NewListingPageRequestTournamentReview",      props: { name: "NewListingPageRequest" } },
    { key: "NewListingPageRequestOpeningRepertoires",    props: { name: "NewListingPageRequest" } },
    { key: "NewListingPageRequestPuzzleRecommendations", props: { name: "NewListingPageRequest" } },
  ]
}

export  const createServiceMenuLinks = {
  name: "createServiceMenu",
  links: [
    { key: "NewListingPageServiceOneOnOneCoaching",      props: { name: "NewListingPageService" } },
    { key: "NewListingPageServiceGameReview",            props: { name: "NewListingPageServiceAsync" } },
  ]
}
