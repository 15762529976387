import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { updateUser, updateChessviaRatingForUser } from '../../util/api';

const SERVER_URL = 'https://api.chessvia.com/api/v1';
const urlRegex = /(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

export const QUERY_CHESS_RATING_REQUEST = 'app/ProfilePage/QUERY_CHESS_RATING_REQUEST';
export const QUERY_CHESS_RATING_SUCCESS = 'app/ProfilePage/QUERY_CHESS_RATING_SUCCESS';

export const QUERY_LICHESS_RATING_REQUEST = 'app/ProfilePage/QUERY_LICHESS_RATING_REQUEST';
export const QUERY_LICHESS_RATING_SUCCESS = 'app/ProfilePage/QUERY_LICHESS_RATING_SUCCESS';

export const QUERY_RECOMMENDED_USERS_REQUEST = 'app/ProfilePage/QUERY_RECOMMENDED_USERS_REQUEST';
export const QUERY_RECOMMENDED_USERS_SUCCESS = 'app/ProfilePage/QUERY_RECOMMENDED_USERS_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
  queryChessRatingError: null,
  chessRatings: [
    {title: "Rapid", rating: 0},
    {title: "Bullet", rating: 0},
    {title: "Blitz", rating: 0}
  ],
  queryLiChessRatingError: null,
  liChessRatings: [
    {title: "Rapid", rating: 0},
    {title: "Bullet", rating: 0},
    {title: "Blitz", rating: 0}
  ],
  queryRecommendedUsersError: null,
  recommendedUsers: [],
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],

        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload };
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_CHESS_RATING_REQUEST:
      return { ...state, queryChessRatingError: null };
    case QUERY_CHESS_RATING_SUCCESS:
      return { ...state, chessRatings: payload };
    case QUERY_LICHESS_RATING_REQUEST:
      return { ...state, queryLiChessRatingError: null };
    case QUERY_LICHESS_RATING_SUCCESS:
      return { ...state, liChessRatings: payload };
    case QUERY_RECOMMENDED_USERS_REQUEST:
      return { ...state, queryRecommendedUsersError: null };
    case QUERY_RECOMMENDED_USERS_SUCCESS:
      return { ...state, recommendedUsers: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = listingRefs => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const queryChessRatingRequest = () => ({
  type: QUERY_CHESS_RATING_REQUEST,
});

export const queryChessRatingSuccess = ratings => ({
  type: QUERY_CHESS_RATING_SUCCESS,
  payload: ratings,
});

export const queryLiChessRatingRequest = () => ({
  type: QUERY_LICHESS_RATING_REQUEST,
});

export const queryLiChessRatingSuccess = ratings => ({
  type: QUERY_LICHESS_RATING_SUCCESS,
  payload: ratings,
});

export const queryRecommendedUsersRequest = () => ({
  type: QUERY_RECOMMENDED_USERS_REQUEST,
});

export const queryRecommendedUsersSuccess = users => ({
  type: QUERY_RECOMMENDED_USERS_SUCCESS,
  payload: users,
});

// ================ Thunks ================ //

export const queryUserListings = (userId, config) => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId));

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return sdk.listings
    .query({
      author_id: userId,
      include: ['author', 'images'],
      pub_listingType: config.listing.listingTypes.map(config => !config.hide ? config.listingType : null),
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    })
    .then(response => {
      console.log('response', response)
      // Pick only the id and type properties from the response listings
      const listingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(listingRefs));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const queryChessRatings = (currentUser) => (dispatch, getState, sdk) => {
  dispatch(queryChessRatingRequest());
  const { profile } = currentUser.attributes || {};
  const { publicData: { chessAccount } } = profile || {};
  let chessUsername = chessAccount;
  if (chessUsername && (chessUsername.match(urlRegex) == chessUsername)) {
    chessUsername = chessUsername.split('/').pop();
  }

  fetch(`${SERVER_URL}/get_chess_ratings?username=${chessUsername}`, {
    method: 'GET'
  })
  .then(response => response.json())
  .then(data => {
    dispatch(queryChessRatingSuccess(data.results));
  })
};

export const queryLiChessRatings = (currentUser) => (dispatch, getState, sdk) => {
  dispatch(queryLiChessRatingRequest());
  const { profile } = currentUser.attributes || {};
  const { publicData: { lichessAccount } } = profile || {};
  let liChessUsername = lichessAccount;
  if (liChessUsername && (liChessUsername.match(urlRegex) == liChessUsername)) {
    liChessUsername = liChessUsername.split('/').pop();
  }
  fetch(`${SERVER_URL}/get_lichess_ratings?username=${liChessUsername}`, {
    method: 'GET'
  })
  .then(response => response.json())
  .then(data => {
    dispatch(queryLiChessRatingSuccess(data.results));
  })
};

export const queryRecommendedUsers = (currentUser) => (dispatch, getState, sdk) => {
  dispatch(queryRecommendedUsersRequest());
  const { id: { uuid } } = currentUser || {};
  const { profile } = currentUser.attributes || {};
  const { publicData: { chessAccount, recommendedUsers }, metadata: { isReviewer } } = profile || {};

  let chessUsername = chessAccount;
  if (chessUsername.match(urlRegex) == chessUsername) {
    chessUsername = chessUsername.split('/').pop();
  }
  const userType = isReviewer ? 'coach' : 'player';
  fetch(`${SERVER_URL}/chess_data?sharetribe_user_id=${uuid}&user_type=${userType}&username=${chessUsername}`, {
    method: 'GET',
  })
  .then(response => response.json())
  .then(data => {
    if (JSON.stringify(recommendedUsers) ===  JSON.stringify(data.results)) {
      console.log('Users already saved!');
    } else {
      const payload = { attributes: {
        id: currentUser.id,
        privateData: {
          recommendedUsers: data.results
        }
      }};
      updateUser(payload).then(res => {
        console.log('res', res);
      });
    }
  })
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const updateChessviaRating = (params) => async (dispatch, getState, sdk) => {
  const { uuid, chessRatings, liChessRatings } = params || {};
  const maxChessComRating = chessRatings.map(r => {
    if (r.title == 'Rapid') {
      return r.rating - 250
    } else if (r.title == 'Blitz') {
      return r.rating
    } else if (r.title == 'Bullet') {
      return r.rating - 200
    }
  });
  const maxLichessRating = chessRatings.map(r => {
    if (r.title == 'Rapid') {
      return r.rating - 250
    } else if (r.title == 'Blitz') {
      return r.rating - 200
    } else if (r.title == 'Bullet') {
      return r.rating - 100
    }
  });

  const chessviaRating = Math.max(...maxChessComRating, ...maxLichessRating);

  const alreadySavedRating = localStorage.getItem('chessviaRating');
  if (parseInt(alreadySavedRating) !== chessviaRating) {
    localStorage.setItem('chessviaRating', chessviaRating);

    const payload = { attributes: {
      id: uuid,
      publicData: {
        chessAccountRating: chessRatings,
        lichessAccountRating: liChessRatings
      }
    }};
    await updateUser(payload).then(res => {
      console.log('res', res);
    });

    updateChessviaRatingForUser({uuid, chessviaRating})
      .then(res => {
        console.log('res', res)
      });
  } else {
    console.log('Already saved!')
  }
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const userId = new UUID(params.id);

  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    dispatch(queryUserListings(userId, config)),
    dispatch(queryUserReviews(userId)),
  ]).then(response => {
    const currentUser = getState().user.currentUser;
    dispatch(queryChessRatings(currentUser));
    dispatch(queryLiChessRatings(currentUser));
    // dispatch(queryRecommendedUsers(currentUser));
  });
};
