/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  {
    key: 'sessionLocation',
    scope: 'public',
    includeForListingTypes: [ 'review-service' ],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'chesscom', label: 'Chess.com Classroom' },
      { option: 'zoom', label: 'Zoom' },
      { option: 'skype', label: 'Skype' },
      { option: 'googlemeet', label: 'Google Meet' },
      { option: 'other', label: 'Other' },      
    ],
    filterConfig: {
      indexForSearch: true,
      searchMode: 'has_any',
      label: 'Session Location',
      group: 'secondary',
    },
    showConfig: {
      label: 'Session Location',
      isDetail: true,
    },
    saveConfig: {
      label: 'Session Location',
      placeholderMessage: 'Select at least one option…',
      isRequired: true,
      requiredMessage: 'You need to select at least one session location.',
    },
  },
  {
    key: 'category',
    scope: 'public',
    includeForListingTypes: [ 'review-request', 'review-service-async' ],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'account-analysis', label: 'Account Analysis' },
      { option: 'game-analysis', label: 'Game Analysis' },
      { option: 'training-plan', label: 'Training Plan' },
      { option: 'other', label: 'Other' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      searchMode: 'has_any',
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: true,
    },
    saveConfig: {
      label: 'Category',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: 'format',
    scope: 'public',
    includeForListingTypes: [ 'review-request', 'review-service-async' ],
    schemaType: 'multi-enum',

    enumOptions: [
      { option: 'annotated-lichess-study', label: 'Annotated Lichess Study' },
      { option: 'video-review', label: 'Video Review (offline)' },
      { option: 'audio-review', label: 'Audio Review (offline)' },
      { option: 'text', label: 'Text (e.g., paragraphs, bullet-points, summary)' },
      { option: 'other', label: 'Other (please specify in the description)' },
    ],
    filterConfig: {
      indexForSearch: true,
      searchMode: 'has_all',
      label: 'Review Format',
      group: 'secondary',
    },
    showConfig: {
      label: 'Review Format',
      isDetail: true,
    },
    saveConfig: {
      label: 'Review Format',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a review format.',
    },
  },
  {
    key: 'coachExperience',
    scope: 'private',
    includeForListingTypes: [ 'reviewer-profile' ],
    schemaType: 'enum',
    enumOptions: [
      { option: 'never', label: "I have never coached before" },
      { option: 'past', label: 'I have coached students in the past' },
      { option: 'current', label: 'I currently coach students' },
    ],
    saveConfig: {
      label: 'What is your experience Chess coaching?',
      placeholderMessage: 'Select an option...',
      isRequired: true,
      requiredMessage: 'You need to provide your coaching experience.',
    },
  },
  {
    key: 'coachExperienceDepth',
    scope: 'private',
    includeForListingTypes: [ 'reviewer-profile' ],
    schemaType: 'enum',
    enumOptions: [
      { option: 'less10', label: 'I have conducted less than 10 coaching sessions' },
      { option: '10to50', label: 'I have conducted between 10 and 50 coaching sessions' },
      { option: '50plus', label: 'I have conducted over 50 coaching sessions' },
    ],
    saveConfig: {
      label: 'If you have coached before, what is the depth of your coaching experience?',
      placeholderMessage: 'Select an option...',
      isRequired: false,
    },
  },
  {
    key: 'coachGetStudents',
    scope: 'private',
    includeForListingTypes: [ 'reviewer-profile' ],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'own', label: 'In person (on my own)' },
      { option: 'academy', label: 'In person (under a chess academy)' },
      { option: 'chesscom', label: 'Chess.com' },
      { option: 'lichess', label: 'Lichess.org' },
      { option: 'other', label: 'Other online website (please write below)' },
    ],
    saveConfig: {
      label: 'Through what means do you get students?',
      placeholderMessage: 'Select an option...',
      isRequired: true,
    },
  },
  {
    key: 'coachGetStudentsOther',
    scope: 'private',
    includeForListingTypes: [ 'reviewer-profile' ],
    schemaType: 'text',
    saveConfig: {
      label: 'Other online website (if applicable)',
      placeholderMessage: 'Please put other websites here if applicable',
      isRequired: false,
    },
  },
  {
    key: 'coachStandOut',
    scope: 'private',
    includeForListingTypes: [ 'reviewer-profile' ],
    schemaType: 'text',
    saveConfig: {
      label: 'What makes you stand out as a coach?',
      placeholderMessage: 'What makes you stand out compared to other coaches?',
      isRequired: true,
      requiredMessage: 'You need to provide an answer to this question.',
    },
  },
  {
    key: 'lichessInfo',
    scope: 'public',
    includeForListingTypes: [ 'reviewer-profile' ],
    schemaType: 'text',
    showConfig: {
      label: 'Lichess Account',
    },
    saveConfig: {
      label: 'Lichess Account (if applicable)',
      placeholderMessage: 'What is your lichess username',
      isRequired: false,
    },
  },
  {
    key: 'chessComInfo',
    scope: 'public',
    includeForListingTypes: [ 'reviewer-profile' ],
    schemaType: 'text',
    showConfig: {
      label: 'Chess.com Account',
    },
    saveConfig: {
      label: 'Chess.com Account (if applicable)',
      placeholderMessage: 'What is your chess.com username',
      isRequired: false,
    },
  },
  {
    key: 'language',
    scope: 'public',
    includeForListingTypes: [ 'reviewer-profile' ],
    schemaType: 'multi-enum',
    enumOptions: [
      { option: "english", label: "English"},
      { option: "spanish", label: "Spanish"},
      { option: "russian", label: "Russian"},
      { option: "german", label: "German"},
      { option: "french", label: "French"},
      { option: "japanese", label: "Japanese"},
      { option: "portuguese", label: "Portuguese"},
      { option: "turkish", label: "Turkish"},
      { option: "italian", label: "Italian"},
      { option: "persian", label: "Persian"},
      { option: "dutch", label: "Dutch"},
      { option: "chinese", label: "Chinese"},
      { option: "polish", label: "Polish"},
      { option: "vietnamese", label: "Vietnamese"},
      { option: "indonesian", label: "Indonesian"},
      { option: "czech", label: "Czech"},
      { option: "arabic", label: "Arabic"},
      { option: "korean", label: "Korean"},
      { option: "ukrainian", label: "Ukrainian"},
      { option: "greek", label: "Greek"},
      { option: "hebrew", label: "Hebrew"},
      { option: "swedish", label: "Swedish"},
      { option: "romanian", label: "Romanian"},
      { option: "hungarian", label: "Hungarian"},
      { option: "thai", label: "Thai"},
      { option: "danish", label: "Danish"},
      { option: "slovak", label: "Slovak"},
      { option: "finnish", label: "Finnish"},
      { option: "bulgarian", label: "Bulgarian"},
      { option: "serbian", label: "Serbian"},
      { option: "norwegian", label: "Norwegian"},
      { option: "croatian", label: "Croatian"},
      { option: "lithuanian", label: "Lithuanian"},
      { option: "slovenian", label: "Slovenian"},
      { option: "catalan", label: "Catalan"},
      { option: "estonian", label: "Estonian"},
      { option: "latvian", label: "Latvian"},
      { option: "hindi", label: "Hindi"},
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      searchMode: 'has_any',
      label: 'Language',
      group: 'secondary',
    },
    showConfig: {
      label: 'Language',
    },
    saveConfig: {
      label: 'Language',
      placeholderMessage: 'What languages can you use to coach?',
      isRequired: true,
      requiredMessage: 'You need to provide a language.',
    },
  },
  {
    key: 'country',
    scope: 'public',
    includeForListingTypes: [ 'reviewer-profile' ],
    schemaType: 'enum',
    enumOptions: [
      { option: "afghanistan", label: "Afghanistan"},
      { option: "albania", label: "Albania"},
      { option: "algeria", label: "Algeria"},
      { option: "andorra", label: "Andorra"},
      { option: "angola", label: "Angola"},
      { option: "antigua-and-barbuda", label: "Antigua and Barbuda"},
      { option: "argentina", label: "Argentina"},
      { option: "armenia", label: "Armenia"},
      { option: "australia", label: "Australia"},
      { option: "austria", label: "Austria"},
      { option: "azerbaijan", label: "Azerbaijan"},
      { option: "bahamas", label: "Bahamas"},
      { option: "bahrain", label: "Bahrain"},
      { option: "bangladesh", label: "Bangladesh"},
      { option: "barbados", label: "Barbados"},
      { option: "belarus", label: "Belarus"},
      { option: "belgium", label: "Belgium"},
      { option: "belize", label: "Belize"},
      { option: "benin-dahomey", label: "Benin (Dahomey)"},
      { option: "bolivia", label: "Bolivia"},
      { option: "bosnia-and-herzegovina", label: "Bosnia and Herzegovina"},
      { option: "botswana", label: "Botswana"},
      { option: "brazil", label: "Brazil"},
      { option: "brunei", label: "Brunei"},
      { option: "brunswick-and-lüneburg", label: "Brunswick and Lüneburg"},
      { option: "bulgaria", label: "Bulgaria"},
      { option: "burkina-faso-upper-volta", label: "Burkina Faso (Upper Volta)"},
      { option: "burma", label: "Burma"},
      { option: "burundi", label: "Burundi"},
      { option: "cabo-verde", label: "Cabo Verde"},
      { option: "cambodia", label: "Cambodia"},
      { option: "cameroon", label: "Cameroon"},
      { option: "canada", label: "Canada"},
      { option: "cayman-islands", label: "Cayman Islands"},
      { option: "central-african-republic", label: "Central African Republic"},
      { option: "chad", label: "Chad"},
      { option: "chile", label: "Chile"},
      { option: "china", label: "China"},
      { option: "colombia", label: "Colombia"},
      { option: "comoros", label: "Comoros"},
      { option: "costa-rica", label: "Costa Rica"},
      { option: "cote-divoire-ivory-coast", label: "Cote d’Ivoire (Ivory Coast)"},
      { option: "croatia", label: "Croatia"},
      { option: "cuba", label: "Cuba"},
      { option: "cyprus", label: "Cyprus"},
      { option: "czechia", label: "Czechia"},
      { option: "czechoslovakia", label: "Czechoslovakia"},
      { option: "democratic-republic-of-the-congo", label: "Democratic Republic of the Congo"},
      { option: "denmark", label: "Denmark"},
      { option: "djibouti", label: "Djibouti"},
      { option: "dominica", label: "Dominica"},
      { option: "dominican-republic", label: "Dominican Republic"},
      { option: "ecuador", label: "Ecuador"},
      { option: "egypt", label: "Egypt"},
      { option: "el-salvador", label: "El Salvador"},
      { option: "equatorial-guinea", label: "Equatorial Guinea"},
      { option: "eritrea", label: "Eritrea"},
      { option: "estonia", label: "Estonia"},
      { option: "eswatini", label: "Eswatini"},
      { option: "ethiopia", label: "Ethiopia"},
      { option: "fiji", label: "Fiji"},
      { option: "finland", label: "Finland"},
      { option: "france", label: "France"},
      { option: "gabon", label: "Gabon"},
      { option: "gambia", label: "Gambia"},
      { option: "georgia", label: "Georgia"},
      { option: "germany", label: "Germany"},
      { option: "ghana", label: "Ghana"},
      { option: "greece", label: "Greece"},
      { option: "grenada", label: "Grenada"},
      { option: "guatemala", label: "Guatemala"},
      { option: "guinea", label: "Guinea"},
      { option: "guinea-bissau", label: "Guinea-Bissau"},
      { option: "guyana", label: "Guyana"},
      { option: "haiti", label: "Haiti"},
      { option: "holy-see", label: "Holy See"},
      { option: "honduras", label: "Honduras"},
      { option: "hungary", label: "Hungary"},
      { option: "iceland", label: "Iceland"},
      { option: "india", label: "India"},
      { option: "indonesia", label: "Indonesia"},
      { option: "iran", label: "Iran"},
      { option: "iraq", label: "Iraq"},
      { option: "ireland", label: "Ireland"},
      { option: "israel", label: "Israel"},
      { option: "italy", label: "Italy"},
      { option: "jamaica", label: "Jamaica"},
      { option: "japan", label: "Japan"},
      { option: "jordan", label: "Jordan"},
      { option: "kazakhstan", label: "Kazakhstan"},
      { option: "kenya", label: "Kenya"},
      { option: "kiribati", label: "Kiribati"},
      { option: "korea", label: "Korea"},
      { option: "kosovo", label: "Kosovo"},
      { option: "kuwait", label: "Kuwait"},
      { option: "kyrgyzstan", label: "Kyrgyzstan"},
      { option: "laos", label: "Laos"},
      { option: "latvia", label: "Latvia"},
      { option: "lebanon", label: "Lebanon"},
      { option: "lesotho", label: "Lesotho"},
      { option: "liberia", label: "Liberia"},
      { option: "libya", label: "Libya"},
      { option: "liechtenstein", label: "Liechtenstein"},
      { option: "lithuania", label: "Lithuania"},
      { option: "luxembourg", label: "Luxembourg"},
      { option: "madagascar", label: "Madagascar"},
      { option: "malawi", label: "Malawi"},
      { option: "malaysia", label: "Malaysia"},
      { option: "maldives", label: "Maldives"},
      { option: "mali", label: "Mali"},
      { option: "malta", label: "Malta"},
      { option: "marshall-islands", label: "Marshall Islands"},
      { option: "mauritania", label: "Mauritania"},
      { option: "mauritius", label: "Mauritius"},
      { option: "mexico", label: "Mexico"},
      { option: "micronesia", label: "Micronesia"},
      { option: "moldova", label: "Moldova"},
      { option: "monaco", label: "Monaco"},
      { option: "mongolia", label: "Mongolia"},
      { option: "montenegro", label: "Montenegro"},
      { option: "morocco", label: "Morocco"},
      { option: "mozambique", label: "Mozambique"},
      { option: "namibia", label: "Namibia"},
      { option: "nauru", label: "Nauru"},
      { option: "nepal", label: "Nepal"},
      { option: "netherlands", label: "Netherlands"},
      { option: "new-zealand", label: "New Zealand"},
      { option: "nicaragua", label: "Nicaragua"},
      { option: "niger", label: "Niger"},
      { option: "nigeria", label: "Nigeria"},
      { option: "north-macedonia", label: "North Macedonia"},
      { option: "norway", label: "Norway"},
      { option: "oman", label: "Oman"},
      { option: "pakistan", label: "Pakistan"},
      { option: "palau", label: "Palau"},
      { option: "panama", label: "Panama"},
      { option: "papua-new-guinea", label: "Papua New Guinea"},
      { option: "paraguay", label: "Paraguay"},
      { option: "peru", label: "Peru"},
      { option: "philippines", label: "Philippines"},
      { option: "poland", label: "Poland"},
      { option: "portugal", label: "Portugal"},
      { option: "qatar", label: "Qatar"},
      { option: "republic-of-korea-south-korea", label: "Republic of Korea (South Korea)"},
      { option: "republic-of-the-congo", label: "Republic of the Congo"},
      { option: "romania", label: "Romania"},
      { option: "russia", label: "Russia"},
      { option: "rwanda", label: "Rwanda"},
      { option: "saint-kitts-and-nevis", label: "Saint Kitts and Nevis"},
      { option: "saint-lucia", label: "Saint Lucia"},
      { option: "saint-vincent-and-the-grenadines", label: "Saint Vincent and the Grenadines"},
      { option: "samoa", label: "Samoa"},
      { option: "san-marino", label: "San Marino"},
      { option: "sao-tome-and-principe", label: "Sao Tome and Principe"},
      { option: "saudi-arabia", label: "Saudi Arabia"},
      { option: "senegal", label: "Senegal"},
      { option: "serbia", label: "Serbia"},
      { option: "seychelles", label: "Seychelles"},
      { option: "sierra-leone", label: "Sierra Leone"},
      { option: "singapore", label: "Singapore"},
      { option: "slovakia", label: "Slovakia"},
      { option: "slovenia", label: "Slovenia"},
      { option: "solomon-islands", label: "Solomon Islands"},
      { option: "somalia", label: "Somalia"},
      { option: "south-africa", label: "South Africa"},
      { option: "south-sudan", label: "South Sudan"},
      { option: "spain", label: "Spain"},
      { option: "sri-lanka", label: "Sri Lanka"},
      { option: "sudan", label: "Sudan"},
      { option: "suriname", label: "Suriname"},
      { option: "sweden", label: "Sweden"},
      { option: "switzerland", label: "Switzerland"},
      { option: "syria", label: "Syria"},
      { option: "tajikistan", label: "Tajikistan"},
      { option: "tanzania", label: "Tanzania"},
      { option: "thailand", label: "Thailand"},
      { option: "timor-leste", label: "Timor-Leste"},
      { option: "togo", label: "Togo"},
      { option: "tonga", label: "Tonga"},
      { option: "trinidad-and-tobago", label: "Trinidad and Tobago"},
      { option: "tunisia", label: "Tunisia"},
      { option: "turkey", label: "Turkey"},
      { option: "turkmenistan", label: "Turkmenistan"},
      { option: "tuvalu", label: "Tuvalu"},
      { option: "uganda", label: "Uganda"},
      { option: "ukraine", label: "Ukraine"},
      { option: "united-arab-emirates", label: "United Arab Emirates"},
      { option: "united-kingdom", label: "United Kingdom"},
      { option: "united-states", label: "United States"},
      { option: "uruguay", label: "Uruguay"},
      { option: "uzbekistan", label: "Uzbekistan"},
      { option: "vanuatu", label: "Vanuatu"},
      { option: "venezuela", label: "Venezuela"},
      { option: "vietnam", label: "Vietnam"},
      { option: "yemen", label: "Yemen"},
      { option: "zambia", label: "Zambia"},
      { option: "zimbabwe", label: "Zimbabwe"},
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      searchMode: 'has_any',
      label: 'Country',
      group: 'secondary',
    },
    showConfig: {
      label: 'Country',
    },
    saveConfig: {
      label: 'Country',
      placeholderMessage: 'What country do you reside in?',
      isRequired: true,
      requiredMessage: 'You need to provide a country.',
    },
  },
  /*
  // {
  //   key: 'chessRating',
  //   scope: 'public',
  //   includeForListingTypes: [ 'reviewer-profile' ],
  //   schemaType: 'long',
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Chess Rating',
  //     group: 'primary',
  //     min: 1000,
  //     max: 2000,
  //     step: 100,
  //   },
  //   showConfig: {
  //     label: 'Chess Rating',
  //   },
  // },
  */
  {
    key: 'chessviaRating',
    scope: 'public',
    includeForListingTypes: [ 'reviewer-profile', 'review-service', 'review-service-async' ],
    schemaType: 'long',
    filterConfig: {
      indexForSearch: true,
      label: 'Chessvia Rating',
      group: 'primary',
      min: 0,
      max: 5000,
      step: 100,
    },
    showConfig: {
      label: 'Chessvia Rating',
    },
  },
  
  {
    key: 'maxDayLimit',
    scope: 'public',
    includeForListingTypes: [ 'review-request' ],
    schemaType: 'long',
    showConfig: {
      label: 'Maximum Days',
    },
    saveConfig: {
      label: 'Maximum Days',
      placeholderMessage: 'What is the maximum number of days you are willing to wait for a review?',
      isRequired: true,
      requiredMessage: 'You need to provide the maximum number of days.'
    }
  },
  {
    key: 'acceptingServiceBooking',
    scope: 'public',
    includeForListingTypes: [ 'review-service', 'review-service-async' ],
    schemaType: 'enum',
    enumOptions: [
      { option: 'accept', label: 'Yes' },
      { option: 'decline', label: 'No' },
    ],
    saveConfig: {
      label: 'Allow players to purchase this listing?',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'Choose an option whether you want to accept bookings for this service or no?',
    },
  },
  {
    key: 'acceptOffersFromCoaches',
    scope: 'public',
    includeForListingTypes: [ 'review-request' ],
    schemaType: 'enum',
    enumOptions: [
      { option: 'accept', label: 'Yes' },
      { option: 'decline', label: 'No' },
    ],
    saveConfig: {
      label: 'Accept offers from coaches on this request?',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'Choose an option whether you want to accept Offers for this request or no?',
    },
  },

  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:     Unique string. This will be saved to listing's public data on
 *                    EditListingWizard.
 * - label            Label for the listing type. Used as microcopy for options to select
 *                    listing type in EditListingWizard.
 * - transactionType  Set of configurations how this listing type will behave when transaction is
 *                    created.
 *   - process          Transaction process.
 *                      The process must match one of the processes that this client app can handle
 *                      (check src/util/transaction.js) and the process must also exists in correct
 *                      marketplace environment.
 *   - alias            Valid alias for the aforementioned process. This will be saved to listing's
 *                      public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType         Unit type is mainly used as pricing unit. This will be saved to
 *                      transaction's protected data.
 *                      Recommendation: don't use same unit types in completely different processes
 *                      ('item' sold should not be priced the same as 'item' booked).
 * - stockType        This is relevant only to listings with product-selling listing type.
 *                    If set to 'oneItem', stock management is not showed and the listing is
 *                    considered unique (stock = 1).
 *                    Possible values: 'oneItem' and 'multipleItems'.
 *                    Default: 'multipleItems'.
 */

export const listingTypes = [
  {
    listingType: 'review-request',
    label: 'Review Request',
    transactionType: {
      process: 'review-request',
      alias: 'review-request/release-1',
      unitType: 'item',
    },
    stockType: 'oneItem',
    hide: false,
    imageRequired: false,
  },
  {
    listingType: 'review-service',
    label: '1-on-1 Review Service',
    transactionType: {
      process: 'review-service',
      alias: 'review-service/release-1',
      unitType: 'hour',
    },
    stockType: 'maxItem',
    hide: false,
    imageRequired: false,
  },
  {
    listingType: 'review-service-async',
    label: 'Asynchronous Review Service',
    transactionType: {
      process: 'review-service-async',
      alias: 'review-service-async/release-1',
      unitType: 'item',
    },
    stockType: 'maxItem',
    hide: false,
    imageRequired: false,
  },
  {
    listingType: 'reviewer-profile',
    label: 'Reviewer Profile',
    transactionType: {
      process: 'reviewer-profile',
      alias: 'reviewer-profile/release-1',
      unitType: 'item',
    },
    hide: true,
    imageRequired: true,
  },
  {
    listingType: 'direct-service',
    label: 'Direct Service',
    transactionType: {
      process: 'direct-service',
      alias: 'direct-service/release-1',
      unitType: 'item',
    },
    hide: true,
    imageRequired: false,
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
