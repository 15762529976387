import React from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { propTypes } from '../../util/types';
import { Footer } from '../../components';

export const FooterContainerComponent = props => {
  const {
    currentUser,
    isAuthenticated,
    ...rest
  } = props;

  return (
    <Footer
      isCoach={currentUser?.attributes.profile.metadata.isReviewer}
      isAuthenticated={isAuthenticated}
      {...rest}
    />
  );
};

FooterContainerComponent.defaultProps = {
  currentUser: null,
  isAuthenticated: null,
};

FooterContainerComponent.propTypes = {
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs isAuthenticated
  const { isAuthenticated } = state.auth;
  // Topbar needs user info.
  const {
    currentUser,
  } = state.user;
  return {
    currentUser,
    isAuthenticated,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const FooterContainer = compose(
  connect(
    mapStateToProps,
  )
)(FooterContainerComponent);

export default FooterContainer;
