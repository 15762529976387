import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
export const ASSET_NAME = 'landing-page';

export const queryTxns = (isReviewer = true) => (dispatch, getState, sdk) => {
  return sdk.transactions
    .query({only: isReviewer ? "sale" : "order"})
    .then(response => {
      const { meta: { totalItems } } = response.data
      return totalItems;
    })
    .catch(e => {
      return 0;
    });
};

export const queryListings = (isReviewer = true) => (dispatch, getState, sdk) => {
  return sdk.listings
    .query({
      pub_listingType: isReviewer ? 'review-service' : 'review-request'
    })
    .then(response => {
      const { meta: { totalItems } } = response.data
      return totalItems;
    })
    .catch(e => {
      return 0;
    });
};

export const loadData = (params, search) => dispatch => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
  return dispatch(fetchPageAssets(pageAsset, true));
};
