import { addMarketplaceEntities } from '../../../ducks/marketplaceData.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../../util/data';
import { fetchCurrentUser } from '../../../ducks/user.duck';

// For live
const RECOMMENDED_COACHES = ["6482884c-48e6-4ca5-a609-dec9c658a1b8", "6478dceb-cf02-4aaa-b896-84c4cd643b49", "647a145c-9ec7-4e4c-812a-d4bc95a62067"];
const RECOMMENDED_USERS = ["64b635d1-ede9-4100-abbc-91a58f013b68", "64b511f9-b346-4ee1-8c1f-8dd31d5690a7", "6478ad02-8309-43a8-965b-cc6d6a175c92", "64b500d0-12b8-47ba-ae67-a57bf39ae884"];

// // For dev
// const RECOMMENDED_COACHES = ["644ec6b8-3b0d-472d-b54b-d98244f3ae2e", "644841dc-c1e8-4183-9aea-5dca1e1de017", "64483fe1-43b6-4047-adee-006d531d76e6"];
// const RECOMMENDED_USERS = ["655982df-c210-4b8f-9341-2d2baf0d8719", "646ac90d-4139-4f1c-8d67-bc984544f377", "644841dc-c1e8-4183-9aea-5dca1e1de017", "64301d2b-9493-4d40-94d7-44fa805db580"]

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/recommendedRequestsPage/SET_INITIAL_STATE';

export const FETCH_RECOMMENDED_REQUESTS_REQUEST = 'app/recommendedRequestsPage/FETCH_RECOMMENDED_REQUESTS_REQUEST';
export const FETCH_RECOMMENDED_REQUESTS_SUCCESS = 'app/recommendedRequestsPage/FETCH_RECOMMENDED_REQUESTS_SUCCESS';
export const FETCH_RECOMMENDED_REQUESTS_ERROR = 'app/recommendedRequestsPage/FETCH_RECOMMENDED_REQUESTS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchRecommendedRequestsProgress: false,
  fetchRecommendedRequestsError: null,
  recommendedRequests: [],
};

const recommendedRequestsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case FETCH_RECOMMENDED_REQUESTS_REQUEST:
      return {
        ...state,
        fetchRecommendedRequestsProgress: true,
        fetchRecommendedRequestsError: null,
        recommendedRequests: [],
      };
    case FETCH_RECOMMENDED_REQUESTS_SUCCESS:
      return {
        ...state,
        recommendedRequests: payload.listingRefs,
        fetchRecommendedRequestsProgress: false,
      };
    case FETCH_RECOMMENDED_REQUESTS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        fetchRecommendedRequestsProgress: false,
        fetchRecommendedRequestsError: payload
      };

    default:
      return state;
  }
};

export default recommendedRequestsPageReducer;

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const fetchRecommendedRequestsRequest = () => ({
  type: FETCH_RECOMMENDED_REQUESTS_REQUEST,
});

export const fetchRecommendedRequestsSuccess = listingRefs => ({
  type: FETCH_RECOMMENDED_REQUESTS_SUCCESS,
  payload: { listingRefs },
});

export const fetchRecommendedRequestsError = e => ({
  type: FETCH_RECOMMENDED_REQUESTS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

// Throwing error for new (loadData may need that info)

export const queryRecommendedRequests = (currentUser) => (dispatch, getState, sdk) => {
  dispatch(fetchRecommendedRequestsRequest());
  const listingImage = {
    variantType: 'cropImage',
    // Aspect ratio for listing image variants (width/height)
    // Note: This will be converted to separate aspectWidth and aspectHeight values
    // to make calculations easier.
    aspectRatio: '1/1',
    // Listings have custom image variants, which are named here.
    variantPrefix: 'listing-card',
  };

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  const isReviewer = currentUser?.attributes?.profile?.metadata?.isReviewer === true;

  let users = [];

  if (isReviewer) {
    users = RECOMMENDED_USERS;
  } else {
    users = RECOMMENDED_COACHES;
  }

  const allPromises = users.map(userId => {
    return sdk.listings
      .query({
        author_id: userId,
        include: ['author', 'images'],
        pub_listingType: 'review-request',
        'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      })
      .then(response => {
        return response;
      })
      .catch(e => console.log(e));
  })

  Promise.all(allPromises).then(response => {
    const listingRefs = response.map(res => {
      return res.data.data.map(({ id, type }) => ({ id, type }));
    })
    const listings = response.map(res => {
      dispatch(addMarketplaceEntities(res));
    })
    
    const flatListingRefs = [].concat(...listingRefs);
    dispatch(fetchRecommendedRequestsSuccess(flatListingRefs));
  });
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([dispatch(fetchCurrentUser())])
    .then(response => {
      const currentUser = getState().user.currentUser;
      return dispatch(queryRecommendedRequests(currentUser));
    })
    .catch(e => {
      throw e;
    });
};
