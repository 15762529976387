import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { ensureUser } from '../../util/data';
import { richText } from '../../util/richText';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ProfileCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

export const ProfileCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    user,
    renderSizes,
    setActiveUser,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const activeUser = ensureUser(user);
  const { id, attributes, profileImage } = activeUser || {};
  const { profile } = attributes || {};
  const { displayName } = profile || {};
  const userId = id.uuid;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
  } = config.layout.listingImage;

  const variantPrefix = 'square-small';
  const variants = profileImage
    ? Object.keys(profileImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveUser
    ? {
        onMouseEnter: () => setActiveUser(activeUser.id),
        onMouseLeave: () => setActiveUser(null),
      }
    : null;

  return (
    <NamedLink className={classes} name="ProfilePage" params={{id: userId}}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        <LazyImage
          rootClassName={css.rootForImage}
          alt={displayName}
          image={profileImage}
          variants={variants}
          sizes={renderSizes}
        />
      </AspectRatioWrapper>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(displayName, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

ProfileCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveUser: null,
};

ProfileCardComponent.propTypes = {
  className: string,
  rootClassName: string,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveUser: func,
};

export default injectIntl(ProfileCardComponent);
