import { storableError } from '../util/errors';

import { convertMoneyToNumber } from '../util/currency';
import { paypalAuthRequest } from '../util/api';
import { 
  fetchCurrentUser,
  updateProfile,
} from './user.duck';

// ================ Action types ================ //

export const CREATE_PARTNER_REFERRAL_REQUEST = 'app/paypal/CREATE_PARTNER_REFERRAL_REQUEST';
export const CREATE_PARTNER_REFERRAL_SUCCESS_LINK = 'app/paypal/CREATE_PARTNER_REFERRAL_SUCCESS_LINK';
export const CREATE_PARTNER_REFERRAL_SUCCESS_STATUS = 'app/paypal/CREATE_PARTNER_REFERRAL_SUCCESS_STATUS';
export const CREATE_PARTNER_REFERRAL_ERROR = 'app/paypal/CREATE_PARTNER_REFERRAL_ERROR';

export const REMOVE_PAYPAL_ACCOUNT_REQUEST = 'app/paypal/REMOVE_PAYPAL_ACCOUNT_REQUEST';
export const REMOVE_PAYPAL_ACCOUNT_SUCCESS = 'app/paypal/REMOVE_PAYPAL_ACCOUNT_SUCCESS';
export const REMOVE_PAYPAL_ACCOUNT_ERROR = 'app/paypal/REMOVE_PAYPAL_ACCOUNT_ERROR';

export const FETCH_PAYPAL_ACCOUNT_STATUS_REQUEST = 'app/paypal/FETCH_PAYPAL_ACCOUNT_STATUS_REQUEST';
export const FETCH_PAYPAL_ACCOUNT_STATUS_SUCCESS = 'app/paypal/FETCH_PAYPAL_ACCOUNT_STATUS_SUCCESS';
export const FETCH_PAYPAL_ACCOUNT_STATUS_ERROR = 'app/paypal/FETCH_PAYPAL_ACCOUNT_STATUS_ERROR';

export const DISBURSE_FUNDS_REQUEST = 'app/paypal/DISBURSE_FUNDS_REQUEST';
export const DISBURSE_FUNDS_SUCCESS = 'app/paypal/DISBURSE_FUNDS_SUCCESS';
export const DISBURSE_FUNDS_ERROR = 'app/paypal/DISBURSE_FUNDS_ERROR';

export const REFUND_REQUEST = 'app/paypal/REFUND_REQUEST';
export const REFUND_SUCCESS = 'app/paypal/REFUND_SUCCESS';
export const REFUND_ERROR = 'app/paypal/REFUND_ERROR';

export const CREATE_PAYPAL_ORDER_REQUEST = 'app/paypal/CREATE_PAYPAL_ORDER_REQUEST';
export const CREATE_PAYPAL_ORDER_SUCCESS = 'app/paypal/CREATE_PAYPAL_ORDER_SUCCESS';
export const CREATE_PAYPAL_ORDER_ERROR = 'app/paypal/CREATE_PAYPAL_ORDER_ERROR';

export const UPDATE_PAYPAL_AFTER_ONBOARDING_REQUEST = 'app/paypal/UPDATE_PAYPAL_AFTER_ONBOARDING_REQUEST';
export const UPDATE_PAYPAL_AFTER_ONBOARDING_SUCCESS = 'app/paypal/UPDATE_PAYPAL_AFTER_ONBOARDING_SUCCESS';
export const UPDATE_PAYPAL_AFTER_ONBOARDING_ERROR = 'app/paypal/UPDATE_PAYPAL_AFTER_ONBOARDING_ERROR';

// ================ Reducer ================ //

const initialState = {
  createPartnerReferralInProgress: false,
  createPartnerReferralError: null,
  createPartnerReferralLinks: null,
  removePaypalAccountInProgress: false,
  removePaypalAccountError: null,
  updatePaypalAfterOnboardingInProgress: false,
  updatePaypalAfterOnboardingError: null,
  fetchPaypalAccountStatusInProgress: false,
  fetchPaypalAccountStatusError: null,
  createPaypalOrderInProgress: false,
  createPaypalOrderError: null,
  disburseFundsInProgress: false,
  disburseFundsError: null,
  refundInProgress: false,
  refundError: null,
  paypalOrderId: null,
  paypalAccount: null,
  paypalAccountStatus: null,
  paypalOnboardingStatus: null,
}

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CREATE_PARTNER_REFERRAL_REQUEST:
      return { ...state, createPartnerReferralInProgress: true, createPartnerReferralError: null }
    case CREATE_PARTNER_REFERRAL_SUCCESS_LINK:
      return { ...state, createPartnerReferralLink: payload }
    case CREATE_PARTNER_REFERRAL_SUCCESS_STATUS:
      return { ...state, createPartnerReferralInProgress: false }
    case CREATE_PARTNER_REFERRAL_ERROR:
      return { ...state, createPartnerReferralInProgress: false, createPartnerReferralError: payload }
    
    case REMOVE_PAYPAL_ACCOUNT_REQUEST:
      return { ...state, removePaypalAccountInProgress: true, removePaypalAccountError: null }
    case REMOVE_PAYPAL_ACCOUNT_SUCCESS:
      return { ...state, removePaypalAccountInProgress: false }
    case REMOVE_PAYPAL_ACCOUNT_ERROR:
      return { ...state, removePaypalAccountInProgress: false, removePaypalAccountError: payload }
    
    case FETCH_PAYPAL_ACCOUNT_STATUS_REQUEST:
      return { ...state, fetchPaypalAccountStatusInProgress: true, fetchPaypalAccountStatusError: null }
    case FETCH_PAYPAL_ACCOUNT_STATUS_SUCCESS:
      return { ...state, fetchPaypalAccountStatusInProgress: false, paypalAccountStatus: payload }
    case FETCH_PAYPAL_ACCOUNT_STATUS_ERROR:
      return { ...state, fetchPaypalAccountStatusInProgress: false, fetchPaypalAccountStatusError: payload }

    case DISBURSE_FUNDS_REQUEST:
      return { ...state, disburseFundsInProgress: true, disburseFundsError: null }
    case DISBURSE_FUNDS_SUCCESS:
      return { ...state, disburseFundsInProgress: false }
    case DISBURSE_FUNDS_ERROR:
      return { ...state, disburseFundsInProgress: false, disburseFundsError: payload }
  
    case REFUND_REQUEST:
      return { ...state, refundInProgress: true, refundError: null }
    case REFUND_SUCCESS:
      return { ...state, refundInProgress: false }
    case REFUND_ERROR:
      return { ...state, refundInProgress: false, refundError: payload }
      
    case CREATE_PAYPAL_ORDER_REQUEST:
      return { ...state, createPaypalOrderInProgress: true, createPaypalOrderError: null }
    case CREATE_PAYPAL_ORDER_SUCCESS:
      return { ...state, createPaypalOrderInProgress: false, paypalOrderId: payload }
    case CREATE_PAYPAL_ORDER_ERROR:
      return { ...state, createPaypalOrderInProgress: false, createPaypalOrderError: payload }
    
    case UPDATE_PAYPAL_AFTER_ONBOARDING_REQUEST:
      return { ...state, updatePaypalAfterOnboardingInProgress: true, updatePaypalAfterOnboardingError: null }
    case UPDATE_PAYPAL_AFTER_ONBOARDING_SUCCESS:
      return { ...state, updatePaypalAfterOnboardingInProgress: false, paypalAccount: payload }
    case UPDATE_PAYPAL_AFTER_ONBOARDING_ERROR:
      return { ...state, updatePaypalAfterOnboardingInProgress: false, updatePaypalAfterOnboardingError: payload }
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const createPartnerReferralRequest = () => ({ type: CREATE_PARTNER_REFERRAL_REQUEST });
export const createPartnerReferralSuccessLink = links => ({ type: CREATE_PARTNER_REFERRAL_SUCCESS_LINK, payload: links });
export const createPartnerReferralSuccessStatus = () => ({ type: CREATE_PARTNER_REFERRAL_SUCCESS_STATUS });
export const createPartnerReferralError = e => ({ type: CREATE_PARTNER_REFERRAL_ERROR, payload: e, error: true });

export const removePaypalAccountRequest = () => ({ type: REMOVE_PAYPAL_ACCOUNT_REQUEST });
export const removePaypalAccountSuccess = () => ({ type: REMOVE_PAYPAL_ACCOUNT_SUCCESS });
export const removePaypalAccountError = e => ({ type: REMOVE_PAYPAL_ACCOUNT_ERROR, payload: e, error: true });

export const fetchPaypalAccountStatusRequest = () => ({ type: FETCH_PAYPAL_ACCOUNT_STATUS_REQUEST });
export const fetchPaypalAccountStatusSuccess = status => ({ type: FETCH_PAYPAL_ACCOUNT_STATUS_SUCCESS, payload: status });
export const fetchPaypalAccountStatusError = e => ({ type: FETCH_PAYPAL_ACCOUNT_STATUS_ERROR, payload: e, error: true });

export const disburseFundsRequest = () => ({ type: DISBURSE_FUNDS_REQUEST });
export const disburseFundsSuccess = () => ({ type: DISBURSE_FUNDS_SUCCESS });
export const disburseFundsError = e => ({ type: DISBURSE_FUNDS_ERROR, payload: e, error: true });

export const refundRequest = () => ({ type: REFUND_REQUEST });
export const refundSuccess = () => ({ type: REFUND_SUCCESS });
export const refundError = e => ({ type: REFUND_ERROR, payload: e, error: true });

export const createPaypalOrderRequest = () => ({ type: CREATE_PAYPAL_ORDER_REQUEST });
export const createPaypalOrderSuccess = orderid => ({ type: CREATE_PAYPAL_ORDER_SUCCESS, payload: orderid });
export const createPaypalOrderError = e => ({ type: CREATE_PAYPAL_ORDER_ERROR, payload: e, error: true });

export const updatePaypalAfterOnboardingRequest = () => ({ type: UPDATE_PAYPAL_AFTER_ONBOARDING_REQUEST });
export const updatePaypalAfterOnboardingSuccess = paypalAccount => ({ type: UPDATE_PAYPAL_AFTER_ONBOARDING_SUCCESS, payload: paypalAccount });
export const updatePaypalAfterOnboardingError = e => ({ type: UPDATE_PAYPAL_AFTER_ONBOARDING_ERROR, payload: e, error: true });

// ================ Thunks ================ //

export const updatePaypalAfterOnboarding = params => (dispatch, getState, sdk) => {
  dispatch(updatePaypalAfterOnboardingRequest())
  const {
    merchantId,
    merchantIdInPayPal,
    permissionsGranted,
    accountStatus,
    consentStatus,
    productIntentId,
    isEmailConfirmed,
    returnMessage,
    riskStatus
  } = params

  const paypalAccount = { 
    id: merchantIdInPayPal,
    permissionsGranted,
    accountStatus,
    consentStatus,
    isEmailConfirmed,
  } 

  dispatch(fetchCurrentUser())
    .then(res => {
      const { currentUser } = getState().user;
      // Paypal return url, update user with data
      // Only update if the merchantId lines up with the actual user's id

      if(merchantId === currentUser.id.uuid) {
        return dispatch(updateProfile({
          publicData: {
            paypalAccountId: paypalAccount.id
          },
          privateData: {
            paypalAccount
          },
        }))
      }
    })
    .then(res => {
      const { currentUser } = getState().user;

      dispatch(updatePaypalAfterOnboardingSuccess(paypalAccount));
      dispatch(fetchPaypalAccountStatus(currentUser.id.uuid))
      return res;
    })
    .catch(e => {
      dispatch(updatePaypalAfterOnboardingError(storableError(e)));
      throw e;
    })
}

export const fetchPaypalAccountStatus = id => (dispatch, getState, sdk) => {
  dispatch(fetchPaypalAccountStatusRequest())
  sdk.users.show({ id })
    .then(res => {
      const currentUser = res.data.data
      // const { paypalStatusLink } = currentUser.attributes.profile.privateData;
      return paypalAuthRequest({
        url: `v1/customer/partners/${process.env.REACT_APP_PAYPAL_PARTNER_ID}/merchant-integrations/${currentUser.attributes.profile.publicData.paypalAccountId}`,
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        },
      })
    })
    .then(res => {
      dispatch(fetchPaypalAccountStatusSuccess(res));
      return res;
    })
    .catch(e => {
      dispatch(fetchPaypalAccountStatusError(storableError(e)));
      throw e;
    })
}

export const createPartnerReferral = params => (dispatch, getState, sdk) => {
  dispatch(createPartnerReferralRequest());

  dispatch(fetchCurrentUser())
    .then(res => {
      const { currentUser } = getState().user;
      return paypalAuthRequest({
        url: 'v2/customer/partner-referrals',
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          tracking_id: currentUser.id.uuid,
          operations: [
            {
              operation: "API_INTEGRATION",
              api_integration_preference: {
                rest_api_integration: {
                  integration_method: "PAYPAL",
                  integration_type: "THIRD_PARTY",
                  third_party_details: {
                    features: ["PAYMENT", "REFUND", "PARTNER_FEE"]
                  }
                }
              }
            }
          ],
          products: [
            "EXPRESS_CHECKOUT"
          ],
          legal_consents: [
            {
              type: "SHARE_DATA_CONSENT",
              granted: true
            }
          ],
          ...params,
        },
      })
    })
    .then(res => {
      dispatch(createPartnerReferralSuccessLink(res.links[1].href));
      return dispatch(updateProfile({
        privateData: { 
          paypalStatusLink: res.links[0].href
        }
      }))
    })
    .then(res => {
      dispatch(createPartnerReferralSuccessStatus())
      return res;
    })
    .catch(e => {
      dispatch(createPartnerReferralError(storableError(e)));
      throw e;
    })
}

export const removePaypalAccount = () => (dispatch, getState, sdk) => {
  dispatch(removePaypalAccountRequest())
  dispatch(updateProfile({
    publicData: {
      paypalAccountId: null
    },
    privateData: {
      paypalAccount: null,
      paypalStatusLink: null
    },
  })).then(res => {
    dispatch(removePaypalAccountSuccess());
    return res;
  })
  .catch(e => {
    dispatch(removePaypalAccountError(storableError(e)));
    throw e;
  })
}

export const createPaypalOrder = props => (dispatch, getState, sdk) => {
  dispatch(createPaypalOrderRequest());
  const {
    tx,
    name,
    paypalAccountId,

    // These are provided by Paypal
    data,
    actions
  } = props

  const { 
    lineItems,
    payinTotal
  } = tx.attributes

  console.log(lineItems);

  const commissionAmount = 
    -convertMoneyToNumber(lineItems.find(e => e.code === "line-item/provider-commission").lineTotal) +  // Reverse since sharetribe uses a negative here
    convertMoneyToNumber(lineItems.find(e => e.code === "line-item/customer-commission").lineTotal)

  const paypalProps = {
    intent: 'CAPTURE',
    purchase_units: [{
      reference_id: tx.id.uuid,
      payee: {
        merchant_id: paypalAccountId,
      },
      payment_instruction: {
        platform_fees: [{
          amount: {
            value: commissionAmount,
            currency_code: lineItems.find(e => e.code === "line-item/provider-commission").lineTotal.currency,
          },
          payee: {
            merchant_id: process.env.REACT_APP_PAYPAL_PARTNER_ID
          }
        }],
        disbursement_mode: 'DELAYED',
      },
      soft_descriptor: name.slice(0,22),
      items: lineItems.filter(e => e.code !== "line-item/provider-commission").map(item =>
        ({
          name: item.code,
          quantity: 1, // For multiple quantity, squash into 1 to make things a little easier on us
          unit_amount: {
            value: convertMoneyToNumber(item.lineTotal),
            currency_code: item.unitPrice.currency,
          }
        })
      ),
      amount: {
        value: convertMoneyToNumber(payinTotal),
        currency_code: payinTotal.currency,
        breakdown: {
          item_total: {
            value: convertMoneyToNumber(payinTotal),
            currency_code: payinTotal.currency,
          }
        }
      }
    }],
    application_context: {
      return_url: 'http://localhost:5000/',
      cancel_url: 'http://localhost:5000/',
      shipping_preference: 'NO_SHIPPING',
      user_action: 'PAY_NOW',
    },
  }

  return actions.order.create(paypalProps)
    .then(res => {
      dispatch(createPaypalOrderSuccess(res));
      return res;
    })
    .catch(e => {
      dispatch(createPaypalOrderError(storableError(e)));
      throw e;
    })
}

export const createDirectPaypalOrder = props => (dispatch, getState, sdk) => {
  dispatch(createPaypalOrderRequest()); // We will reuse the createPaypalOrder stuff since this is essentially the same thing
  const {
    tx,
    name,

    // These are provided by Paypal
    data,
    actions
  } = props

  const { 
    lineItems,
    payinTotal
  } = tx.attributes

  const paypalProps = {
    intent: 'CAPTURE',
    purchase_units: [{
      reference_id: tx.id.uuid,
      soft_descriptor: name.slice(0,22),
      items: [{
        name: name.slice(0,127),
        quantity: lineItems[0].quantity.toString(),
        unit_amount: {
          value: convertMoneyToNumber(lineItems[0].unitPrice),
          currency_code: lineItems[0].unitPrice.currency,
        }
      }],
      amount: {
        value: convertMoneyToNumber(payinTotal),
        currency_code: payinTotal.currency,
        breakdown: {
          item_total: {
            value: convertMoneyToNumber(payinTotal),
            currency_code: payinTotal.currency,
          }
        }
      }
    }],
    application_context: {
      return_url: 'http://localhost:5000/',
      cancel_url: 'http://localhost:5000/',
      shipping_preference: 'NO_SHIPPING',
      user_action: 'PAY_NOW',
    },
  }

  return actions.order.create(paypalProps)
    .then(res => {
      dispatch(createPaypalOrderSuccess(res));
      return res;
    })
    .catch(e => {
      dispatch(createPaypalOrderError(storableError(e)));
      throw e;
    })
}

export const disburseFunds = (txId) => (dispatch, getState, sdk) => { // IMPORTANT: Paypal autodisburses after 28 days.
  dispatch(disburseFundsRequest());

  return sdk.transactions.show({ id: txId })
  .then(res => {
    const { paypalCaptureId } = res.data.data.attributes.protectedData;

    return paypalAuthRequest({
      url: `v1/payments/referenced-payouts-items`,
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        reference_id: paypalCaptureId,
        reference_type: 'TRANSACTION_ID',
      }
    })
  }).then(res => {
    dispatch(disburseFundsSuccess());
    return res;
  }).catch(e => {
    dispatch(disburseFundsError(e));
    throw e;
  })
}

export const refund = (txId) => (dispatch, getState, sdk) => {
  dispatch(refundRequest())
  
  return sdk.transactions.show({ id: txId, include: ['provider'], expand: true })
    .then(res => {
      console.log(res);
      const { paypalCaptureId } = res.data.data.attributes.protectedData;
      const { provider } = res.data.data.relationships;
      const author = res.data.included.find(obj => obj.id.uuid === provider.data.id.uuid && obj.type === provider.data.type)
      return paypalAuthRequest({
        url: `v2/payments/captures/${paypalCaptureId}/refund`,
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "PayPal-Request-Id": txId,
        },
        sellerPayerId: author.attributes.profile.publicData.paypalAccountId,
      }).then(res => {
        dispatch(refundSuccess());
        return res;
      }).catch(e => {
        dispatch(refundError(e));
        throw e;
      })
    })
}