import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  aiAnalysisMenuLinks,
  moreMenuLinks,
  browseMenuLinks,
  createRequestMenuLinks,
  createServiceMenuLinks,
  browseMenuCoachLinks,
} from '../../../util/topbarLinks';

import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    listingTypes,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isReviewer = isAuthenticated && currentUser?.attributes?.profile?.metadata?.isReviewer === true;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      listingTypes={listingTypes}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const makeMenu = props => {
    const { name, links } = props;
    
    const isReviewer = currentUser?.attributes?.profile?.metadata?.isReviewer === true;
    return (
      <Menu key={name}>
        <MenuLabel className={css.topbarMenuLabel}>
          <span className={css.topbarLinkLabel}>
            <FormattedMessage id={`TopbarDesktop.${name}`} />
          </span>
        </MenuLabel>
        <MenuContent className={css.topbarMenuContent}>
          {links.map(link => {
            return (
              <MenuItem key={link.key}>
                <NamedLink
                  className={classNames(css.topbarMenuLink, isReviewer && link.key === "NewListingPageReviewer" ? css.becomeACoach : '' )}
                  {...link.props}
                >
                  <span className={css.menuItemBorder} />
                  <FormattedMessage id={`TopbarDesktop.link${link.key}`} />
                </NamedLink>
              </MenuItem>
            )
          })}
        </MenuContent>
      </Menu>
    )
  }

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ProfilePage">
          {currentUser != null &&
            currentUser.id ? (
                <NamedLink
                  className={css.topbarMenuLink}
                  name="ProfilePage"
                  params={{ id: currentUser.id.uuid }}
                >
                  <span className={css.menuItemBorder} />
                  <FormattedMessage id="TopbarDesktop.linkProfilePage" />
                </NamedLink>
              ) : <></>}
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.topbarMenuLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkProfileSettingsPage" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.topbarMenuLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkManageListingsPage" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.topbarMenuLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkAccountSettingsPage" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="InboxPage">
          <NamedLink
            className={classNames(css.topbarMenuLink, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <span className={css.menuItemBorder} />
            <span className={css.inbox}>
              <FormattedMessage id="TopbarDesktop.linkInboxPage" />
              {notificationDot}
            </span>
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const signupLink = authenticatedOnClientSide ? null : (
    <NamedLink name="SignupPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
     </NamedLink>
  );

  const loginLink = (
    <NamedLink name="LoginPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const howItWorksLink = (
    <NamedLink name="HowItWorks" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.howItWorks" />
      </span>
    </NamedLink>
  );

  const createARequest = (
    <NamedLink name="NewListingPageRequest" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.NewListingPageRequest" />
      </span>
    </NamedLink>
  );

  const ChatbotPage = (
    <NamedLink name="ChatbotPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.ChatbotPage" />
      </span>
    </NamedLink>
  );


  const aiAnalysisMenu = makeMenu(aiAnalysisMenuLinks);
  const moreMenu = makeMenu(moreMenuLinks);
  const browseMenu = makeMenu(browseMenuLinks);
  const createRequestMenu = makeMenu(createRequestMenuLinks);
  const createServiceMenu = makeMenu(createServiceMenuLinks);
  const browsecoachMenu = makeMenu(browseMenuCoachLinks);

  const topbarItems = isAuthenticated ? (
    isReviewer ? [
      search,
      ChatbotPage,
      howItWorksLink,
      createServiceMenu,
      createARequest,
      browsecoachMenu,
      moreMenu,
      profileMenu
    ] : [
      search,
      ChatbotPage,
      howItWorksLink,
      createARequest,
      browseMenu,
      moreMenu,
      profileMenu
    ]
  ) : [
    ChatbotPage,
    howItWorksLink,
    moreMenu,
    signupLink,
    loginLink,
    
    
  ]

  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        format="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      />
      {topbarItems}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
