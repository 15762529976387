/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';
import {
  aiAnalysisMenuLinks,
  moreMenuLinks,
  browseMenuLinks,
  createRequestMenuLinks,
  createServiceMenuLinks,
} from '../../../util/topbarLinks';

import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../../components';
import css from './TopbarMobileMenu.module.css';
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from "react-headless-accordion";

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const isReviewer = isAuthenticated && currentUser?.attributes?.profile?.metadata?.isReviewer === true;

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        </div>
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const isReviewerMobile = currentUser?.attributes?.profile?.metadata?.isReviewer === true;

  const makeMenu = props => {
    const { name, links } = props;
    return (
      <AccordionItem className={css.linkBox} >
        <AccordionHeader className={css.linkBoxBtn} >
          <h3 className={`accordion-title`}>
            <FormattedMessage id={`TopbarDesktop.${name}`}/>
          </h3>
        </AccordionHeader>
        <AccordionBody className={css.subLinksBody}>
          <div className="accordion-body">
            <ul key={name}>
              {links.map(link => {
                return(
                  <li>
                    <NamedLink
                      className={classNames(css.topbarMenuLink, isReviewerMobile && link.key === "NewListingPageReviewer" ? css.becomeACoach : '' )}
                      {...link.props}
                    >
                      <span className={css.menuItemBorder} />
                      <FormattedMessage id={`TopbarDesktop.link${link.key}`} />
                    </NamedLink>
                  </li>
                )
              })}
            </ul>
          </div>
        </AccordionBody>
      </AccordionItem>
    )
  }

  const profileMenu = (
    <AccordionItem className={css.linkBox} >
      <AccordionHeader className={css.linkBoxBtn} >
        <h3 className={`accordion-title`}>
          <FormattedMessage id="TopbarMobileMenu.Profile" />
        </h3>
      </AccordionHeader>
      <AccordionBody className={css.subLinksBody}>
        <div className="accordion-body">
          <ul>
            <li>
              <NamedLink
                name="InboxPage"
                params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
              >
                <FormattedMessage id="TopbarMobileMenu.inboxLink" />
                {notificationCountBadge}
              </NamedLink>
            </li>
            <li>
              <NamedLink name="ManageListingsPage">
                <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
              </NamedLink>
             </li>
            <li>
              <NamedLink name="ProfileSettingsPage">
                <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
              </NamedLink>
            </li>
            <li>
              <NamedLink name="AccountSettingsPage">
                <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
              </NamedLink>
            </li>
          </ul>
        </div>
      </AccordionBody>
    </AccordionItem>
  )

  const aiAnalysisMenu = makeMenu(aiAnalysisMenuLinks);
  const moreMenu = makeMenu(moreMenuLinks);
  const browseMenu = makeMenu(browseMenuLinks);
  const createRequestMenu = makeMenu(createRequestMenuLinks);
  const createServiceMenu = makeMenu(createServiceMenuLinks);

  const topbarItems = isReviewer ? [
    profileMenu,
    createServiceMenu,
    createRequestMenu,
    browseMenu,
    aiAnalysisMenu,
    moreMenu
  ] : [
    profileMenu,
    createRequestMenu,
    browseMenu,
    aiAnalysisMenu,
    moreMenu
  ]

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        <Accordion className={css.linkBoxWrapper} >
          {topbarItems}
        </Accordion>
        <div className={css.spacer} />
      </div>
      <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
