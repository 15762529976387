import classNames from 'classnames';
import React from 'react'

// Previous Chessvia Icon
/*
const IconChessvia = props => {
  const { rootClassName, className, ...rest } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={116}
      height={178.667}
      className={classes}
      {...rest}
    >
      <path
        d="M42.051 27.062H11.57l9.859 11.166v46.78H38.73s4.93-17.706 7.042-26.961c2.113-9.256 15.795-3.32 10.765 5.734-5.03 9.054-27.666 50.099-27.666 50.099l.024 10.911s-9.602.114-9.815 5.057l.033 14.413h76.859v-13.48c0-5.44-9.91-5.332-9.91-5.332v-12.726l16.248-16.247V50.3c0-18.964-25.021-41.447-40.643-41.447-15.621 0-49.495.302-49.495.302H9.456L22.132 21.83"
        style={{
          opacity: 1,
          fill: "none",
          stroke: "#124429",
          strokeWidth: 6.293,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
      />
      <path
        d="M19.773 24.794h41.85c7.344 0 26.156 13.682 26.156 27.564v39.335"
        style={{
          opacity: 1,
          fill: "none",
          stroke: "#124429",
          strokeWidth: 10.8283,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
      />
      <path
        d="M82.367 91.517h10.82s.294 4.007-3.351 7.211c-3.646 3.205-7.469 2.82-7.469 2.82z"
        style={{
          opacity: 1,
          fill: "#124429",
          strokeWidth: 10.8283,
        }}
      />
      <path
        d="M12.728 148.934h87.858c6.806 0 7.866 4.331 7.866 6.364v13.789H6.982v-13.789c0-3.889 3.205-6.364 5.746-6.364z"
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          stroke: "#124429",
          strokeWidth: 6.293,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
      />
    </svg>
  );
};

export default IconChessvia;
*/

const IconChessvia = props => {
  const { rootClassName, className, ...rest } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 2500 2500"
      className={classes}
      {...rest}
    >
      <style type="text/css">
        {`.st0{fill:#FFFFFF;}.st1{fill:#DAA520;}.st2{fill:#124429;}`}
      </style>
      <rect x="-6.53" y="-6.53" className="st0" width="2513.06" height="2513.06" />
      <g>
        <path
          className="st1"
          d="M1501.73,731.28c60.73,106.09,150.75,282.56,210.11,391.99c-128.89-319.3-179.74-676.06-596.36-709.21v117.82
          C1268.41,522.19,1418.93,601.12,1501.73,731.28z"
        />
        <path
          className="st2"
          d="M1772.18,1528.43l-46.36,0.57l-872.06,11.71l50-96.79c35.7-0.48,803.18-10.58,809.76-10.75
          c-77.63-173.66-200.71-444.6-281.43-627.43c-81.39-180.64-252-177.28-417.22-170.55l94.63,94.6
          c-34.25,81.72-163.1,389.68-194.63,464.8c40.23-0.47,166.79-2.13,208.56-2.76c8.8-9.28,104.42-108,194.27-201.18h-73.68
          l-143.72,141.42l-81.7-1.24c52.01-116.1,142.17-307.58,186.62-406.84c101.11,7.06,150.13,95.78,181.06,185.39
          c40.32,96.11,122.29,291,177.78,423.21c-121.13-1.62-698.29-9.33-698.29-9.33l-36.52-0.48c-36.7,80.99-120.13,256.19-156.2,335.84
          c56.19,0.67,844.99,11.46,1001.59,13.51l21.18,90.15l-1064.98-14.25l-81.38,173.91c54.94-0.6,1384.74-0.31,1401.02,0
          C1910.57,1827.13,1816.03,1620.19,1772.18,1528.43z"
        />
      </g>
    </svg>
  );
};

export default IconChessvia;