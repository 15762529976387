import { types as sdkTypes } from '../../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../../util/data';
import { fetchCurrentUser } from '../../../ducks/user.duck';

// For live
const RECOMMENDED_COACHES = ["6482884c-48e6-4ca5-a609-dec9c658a1b8", "6478dceb-cf02-4aaa-b896-84c4cd643b49", "647a145c-9ec7-4e4c-812a-d4bc95a62067"];
const RECOMMENDED_USERS = ["65511ec2-977a-4973-adf4-636f65fa9034", "64889e01-895f-4c13-a03b-3b71cfbf0e12", "647c9fd0-a6f3-410f-ad79-bc8e506d552b", "6483c57e-315c-430b-b96a-0e4eed04f4aa"];

// // For dev
// const RECOMMENDED_COACHES = ["644ec6b8-3b0d-472d-b54b-d98244f3ae2e", "644841dc-c1e8-4183-9aea-5dca1e1de017", "64483fe1-43b6-4047-adee-006d531d76e6"];
// const RECOMMENDED_USERS = ["655982df-c210-4b8f-9341-2d2baf0d8719", "646ac90d-4139-4f1c-8d67-bc984544f377", "644841dc-c1e8-4183-9aea-5dca1e1de017", "64301d2b-9493-4d40-94d7-44fa805db580"]

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/recommendedUsersPage/SET_INITIAL_STATE';

export const FETCH_RECOMMENDED_USERS_REQUEST = 'app/recommendedUsersPage/FETCH_RECOMMENDED_USERS_REQUEST';
export const FETCH_RECOMMENDED_USERS_SUCCESS = 'app/recommendedUsersPage/FETCH_RECOMMENDED_USERS_SUCCESS';
export const FETCH_RECOMMENDED_USERS_ERROR = 'app/recommendedUsersPage/FETCH_RECOMMENDED_USERS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchRecommendedUsersProgress: false,
  fetchRecommendedUsersError: null,
  recommendedUsers: [],
};

const recommendedUsersPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case FETCH_RECOMMENDED_USERS_REQUEST:
      return {
        ...state,
        fetchRecommendedUsersProgress: true,
        fetchRecommendedUsersError: null,
        recommendedUsers: [],
      };
    case FETCH_RECOMMENDED_USERS_SUCCESS:
      return {
        ...state,
        recommendedUsers: payload.data,
        fetchRecommendedUsersProgress: false,
      };
    case FETCH_RECOMMENDED_USERS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        fetchRecommendedUsersProgress: false,
        fetchRecommendedUsersError: payload
      };

    default:
      return state;
  }
};

export default recommendedUsersPageReducer;

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const fetchRecommendedUsersRequest = () => ({
  type: FETCH_RECOMMENDED_USERS_REQUEST,
});

export const fetchRecommendedUsersSuccess = response => ({
  type: FETCH_RECOMMENDED_USERS_SUCCESS,
  payload: { data: response },
});

export const fetchRecommendedUsersError = e => ({
  type: FETCH_RECOMMENDED_USERS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

// Throwing error for new (loadData may need that info)
export const queryRecommendedUsers = currentUser => (dispatch, getState, sdk) => {
  dispatch(fetchRecommendedUsersRequest());
  const isReviewer = currentUser?.attributes?.profile?.metadata?.isReviewer === true;

  let recommended_users = [];
  if (isReviewer) {
    recommended_users = RECOMMENDED_USERS;
  } else {
    recommended_users = RECOMMENDED_COACHES;
  }
  // const { recommendedUsers: recommended_users } = currentUser?.attributes.profile.privateData || {};
  if (!recommended_users) {
    return dispatch(setInitialState());
  }
  const recommendedUsersUuid = recommended_users.map((id) => new UUID(id))
  dispatch(showUser(recommendedUsersUuid));

  const allPromises = recommendedUsersUuid.map(userId => {
    return dispatch(showUser(userId)); // Returns a promise
  });

  Promise.all(allPromises).then((response) => {
    dispatch(fetchRecommendedUsersSuccess(response));
    return response;
  });
};

export const showUser = userId => (dispatch, getState, sdk) => {
  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      // had to return first element because it denormalise response and return data in array.
      return denormalisedResponseEntities(response)[0];
    })
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([dispatch(fetchCurrentUser())])
    .then(response => {
      const currentUser = getState().user.currentUser;
      return dispatch(queryRecommendedUsers(currentUser));
    })
    .catch(e => {
      throw e;
    });
};
