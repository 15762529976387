import merge from 'lodash/merge';
import { storableError } from '../../util/errors';
import { supportEmailRequest } from '../../util/api';

// ================ Action types ================ //

export const SEND_EMAIL_REQUEST = 'app/SupportPage/SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'app/SupportPage/SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_ERROR = 'app/SupportPage/SEND_EMAIL_ERROR';

// ================ Reducer ================ //

const initialState = {
  emailSent: false,
  sendEmailError: null,
  sendEmailInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SEND_EMAIL_REQUEST:
      return {
        emailSent: false,
        sendEmailInProgress: true,
        sendEmailError: null,
      };
    case SEND_EMAIL_SUCCESS:
      return {
        emailSent: true,
        sendEmailInProgress: false,
        sendEmailError: null
      };
    case SEND_EMAIL_SUCCESS:
      return {
        emailSent: false,
        sendEmailInProgress: false,
        sendEmailError: null,
        sendEmailError: payload
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const sendEmailRequest = () => ({ type: SEND_EMAIL_REQUEST });
export const sendEmailSuccess = () => ({ type: SEND_EMAIL_SUCCESS });

export const sendEmailError = e => ({
  type: SEND_EMAIL_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

/**
 * Send email to support team.
 */
export const sendEmail = params => (dispatch, getState, sdk) => {
  dispatch(sendEmailRequest());
  return supportEmailRequest(params)
    .then(res => {
      if (res.success) {
        dispatch(sendEmailSuccess());
      } else {
        dispatch(sendEmailError());
      }
    })
    .catch(err => {
      dispatch(sendEmailError());
    });
};
